import React from 'react';
import DropZone from '../DropZone';

interface IMediaListProps {
  fichiers: any[];
}

const renderFichier = (fichier: any) => {
  return (
    <>
      {fichier.ext === '.png' ||
      fichier.ext === '.jpeg' ||
      fichier.ext === '.jpg' ? (
        <img src={`${fichier.url}`} />
      ) : (
        <div className="column full-height full-width">
          <span className="material-icons grey">folder</span>
        </div>
      )}
      <div className="name">{fichier.name}</div>
    </>
  );
};

const MediaList: React.FC<IMediaListProps> = ({
  fichiers,
}: IMediaListProps) => {
  return (
    <div className="media-list">
      <label
        className="row"
        style={{ padding: '1rem', borderBottom: '1px solid #d4d4d4' }}
      >
        <span>Liste des médias</span>
      </label>
      {fichiers.length > 0 ? (
        <div className="media-list_content">
          {fichiers.map((fichier: any, index: number) => (
            <div
              className="media-list_item"
              key={index}
              onClick={() => window.open(`${fichier.url}`)}
            >
              {renderFichier(fichier)}
              <button>
                <span className="material-icons grey">open_in_new</span>
              </button>
            </div>
          ))}
        </div>
      ) : (
        <div className="full-width full-height column dgrey">
          <div>Aucun média chargé pour le moment</div>
        </div>
      )}
    </div>
  );
};

interface IMediaGalleryProps {
  consultationId: string;
  fichiers: any[];
  onAddFichiers: (fichier: any) => void;
}

const MediaGallery: React.FC<IMediaGalleryProps> = ({
  consultationId,
  fichiers,
  onAddFichiers,
}: IMediaGalleryProps) => {
  return (
    <div className="media-gallery">
      <MediaList fichiers={fichiers} />
      <DropZone consultationId={consultationId} onAddFichiers={onAddFichiers} />
    </div>
  );
};

export default MediaGallery;
