import { ThunkDispatch } from 'redux-thunk';

export interface AppState {
  isAuth: boolean;
  jwt?: string;
  user?: any;
  error?: string;
}

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_ERROR = 'LOGIN_ERROR',
  LOGOUT = 'LOGOUT';

export interface LoginSuccess {
  type: typeof LOGIN_SUCCESS;
  payload: {
    jwt: string;
    user: any;
  };
}

export interface LoginError {
  type: typeof LOGIN_ERROR;
  payload: any;
}

export interface Logout {
  type: typeof LOGOUT;
}

export type AppDispatch = ThunkDispatch<AppState, void, AppAction>;

export type AppAction = LoginSuccess | LoginError | Logout;
