import React, { useEffect } from 'react';
import LoginPage from './LoginPage';
import {
  ApolloClient,
  createHttpLink,
  DefaultOptions,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { BASE_URL } from '../config/config';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState, store } from '../store';
import { checkAuth, logout } from '../store/app/app.actions';

const httpLink = createHttpLink({
  uri: BASE_URL + '/graphql',
});

export const query = (promise: Promise<any>): typeof promise => {
  return promise
    .then(res => res)
    .catch(err => {
      if (err.status && err.status === 401) store.dispatch(logout());
      else
        throw {
          status: err.status,
          message: err.message,
        };
    });
};

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('TOKEN');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  defaultOptions,
  cache: new InMemoryCache(),
});

export const authHeader = () => ({
  Authorization: `Bearer ${localStorage.getItem('TOKEN')}`,
});

export const AuthProvider: React.FC = (props: any) => {
  const { isAuth } = useSelector((state: StoreState) => state.appState);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkAuth());
  }, []);

  return isAuth ? props.children : <LoginPage />;
};
