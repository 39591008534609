import React, { useEffect, useRef, useState } from 'react';
import { query } from '../../core/AuthProvider';
import {
  createPosologie,
  getPosologies,
} from '../../services/posologieService/posologie.service';
import Suggestion from '../../types/suggestion';
import { Preparation } from '../../models/consultation';
import useClickOutside from '../../hooks/useClickOutside';

interface IPosologieSuggesterProps {
  posologie: string;
  onChange: (posologie: Preparation['posologie']) => void;
}

const PosologieSuggester: React.FC<IPosologieSuggesterProps> = ({
  posologie,
  onChange,
}: IPosologieSuggesterProps) => {
  const inputRef = useRef(null);
  useClickOutside(inputRef, () => setDisplaySuggestions(false));
  const [searchField, setSearchField] = useState<string>(posologie || '');
  const [displaySuggestions, setDisplaySuggestions] = useState<boolean>(false);
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);

  useEffect(() => {
    setSearchField(posologie);
  }, [posologie]);

  const handleOnChange = (suggestion: Suggestion) => {
    onChange(suggestion.titre);
    setSearchField(suggestion.titre);
    setSuggestions([]);
    setDisplaySuggestions(false);
  };

  const handleCreate = (e: any) => {
    e.preventDefault();
    if (searchField && searchField.length > 0) {
      query(createPosologie(searchField))
        .then((res: Suggestion) => handleOnChange(res))
        .catch(err => console.log(err));
    }
  };

  const handleSuggestions = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const searchField = e.target.value;
    setSearchField(searchField);
    if (searchField && searchField.length > 0)
      query(getPosologies(searchField))
        .then(suggestions => {
          setSuggestions(suggestions);
          setDisplaySuggestions(true);
        })
        .catch(err => console.log(err));
    else setSuggestions([]);
  };

  const handleOnFocus = () => {
    setDisplaySuggestions(true);
  };

  const handleOnBlur = () => {
    setDisplaySuggestions(false);
    setSearchField(posologie);
    setSuggestions([]);
  };

  return (
    <div className="posologie-suggester" onFocus={handleOnFocus} ref={inputRef}>
      <form>
        <input value={searchField} onChange={handleSuggestions} />
        {searchField && searchField.length > 0 && posologie !== searchField ? (
          <button type="submit" onClick={handleCreate}>
            <span className="material-icons">check</span>
          </button>
        ) : null}
      </form>

      <div style={{ position: 'relative' }}>
        {displaySuggestions ? (
          <div
            className="posologie-suggestions"
            style={{ marginTop: -(suggestions.length - 1) + 'rem' }}
          >
            {suggestions.map((suggestion, index) => (
              <button
                key={`posologie-${index}`}
                className="posologie-suggestion"
                onClick={() => handleOnChange(suggestion)}
              >
                {suggestion.titre}
              </button>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PosologieSuggester;
