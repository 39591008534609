/**
 * Open a new tab with given query params in the url.
 * @param queryParams {{ key: string, value: string }[]}
 */
export const openInNewTab = (
  queryParams: { key: string; value: string }[] = []
): void => {
  let url = '/';
  if (queryParams?.length > 0) {
    url += `?${queryParams.map(qp => `${qp.key}=${qp.value}&`).join('')}`;
  }
  const newWindow = window.open(url);
  if (newWindow) newWindow.opener = null;
};
