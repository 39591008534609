import Client from '../../models/client';
import { BASE_URL } from '../../config/config';
import { apolloClient, authHeader } from '../../core/AuthProvider';
import moment from 'moment';
import { gql } from '@apollo/client';

const getNewClient = () => ({
  nom: null,
  prenom: null,
  sexe: null,
  age: null,
  dateNaissance: null,
  adresse: {
    line1: null,
    line2: null,
    line3: null,
    line4: null,
    ville: null,
    cp: null,
    pays: 'FRANCE',
  },
  telephone: null,
  email: null,
  profession: null,
  deLaPart: null,
  premiereVisite: moment().format('YYYY-MM-DD'),
  plaintes: [],
  notes: null,
  consultations: [],
});

const getClient = (id: string): Promise<Client> =>
  apolloClient
    .query({
      query: gql`
        query {
          client(id: ${id}) {
            id
            nom
            prenom
            dateNaissance,
            age,
            sexe,
            adresse{
              line1,
              line2,
              line3,
              line4,
              cp,
              ville,
              pays
            },
            telephone,
            email,
            profession,
            premiereVisite,
            plaintes{
              id
              titre
            },
            deLaPart,
            notes,
            consultations{
              id,
              date
            }
          }
        }
      `,
    })
    .then(res => {
      const {
        data: { client },
      } = res;
      return client;
    });

const getClients = (filters: any): Promise<Client[]> => {
  const filtersRow = Object.keys(filters).reduce(
    (acc, curr: string) => acc + `${curr}_contains=${filters[curr]}&`,
    '?'
  );
  return fetch(`${BASE_URL}/clients${filtersRow}`, {
    method: 'GET',
    headers: authHeader(),
  }).then(res => {
    if (!res.ok)
      throw {
        status: res.status,
        message: 'Erreur lors de la récupération des clients',
      };
    return res.json();
  });
};

// const getClients = (filters: any) =>
//   apolloClient
//     .query({
//       query: gql`
//         {
//           clients {
//             id
//             nom
//             prenom
//             dateNaissance
//           }
//         }
//       `,
//     })
//     .then(res => {
//       const {
//         data: { clients },
//       } = res;
//       return clients;
//     });

// const getClients = async (): Promise<Client[]> => {
//   return [client];
// };

const fetchClient = (client: Partial<Client>, id?: string): Promise<Client> =>
  fetch(`${BASE_URL}/clients/${id || ''}`, {
    method: id ? 'PUT' : 'POST',
    headers: { ...authHeader(), 'Content-type': 'application/json' },
    body: JSON.stringify(client),
  }).then(res => {
    if (!res.ok)
      throw {
        status: res.status,
        message: 'Erreur lors de la création du client',
      };
    return res.json();
  });

// const fetchClient = (client: Partial<Client>) =>
//   apolloClient
//     .mutate({
//       mutation: gql`
//         mutation {
//           createClient {
//             input: {
//                 data: ${client}
//           }
//         }
//       `,
//     })
//     .then(res => {
//       const {
//         data: { client },
//       } = res;
//       return client;
//     });

/**
 * Delete a client.
 * @param id {string} the id of the client to delete
 */
const deleteClient = (id: Client['id']): Promise<Client> =>
  fetch(`${BASE_URL}/clients/${id}`, {
    method: 'DELETE',
    headers: { ...authHeader() },
  }).then(res => {
    if (!res.ok)
      throw {
        status: res.status,
        message: 'Erreur lors de la suppression du client',
      };
    return res.json();
  });

const clientService = {
  getNewClient,
  getClient,
  getClients,
  fetchClient,
  deleteClient,
};

export default clientService;
