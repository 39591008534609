import { useEffect } from 'react';
import { toastError } from '../utils/toastr';

const useToastError = (error?: any): void => {
  useEffect(() => {
    if (error) {
      toastError(error.message ? error.message : error);
    }
  }, [error]);
};

export default useToastError;
