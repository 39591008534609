import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getClient } from '../store/client/client.actions';
import useQueryParams from './useQueryParams';

const useGetClientFromQueryParam = (): void => {
  const params = useQueryParams();
  const dispatch = useDispatch();

  const clientId = params.get('clientId');

  useEffect(() => {
    if (clientId) {
      dispatch(getClient(clientId));
    }
  }, [clientId]);
};

export default useGetClientFromQueryParam;
