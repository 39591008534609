import React from 'react';
import { useDispatch } from 'react-redux';
import { setClientAsNew } from '../../store/client/client.actions';
import { setDisplayed } from '../../store/clients/clients.actions';
import './ClientSearchBar.scss';

interface IClientSearchBarProps {
  filters: {
    id?: string;
    nom?: string;
    prenom?: string;
  };
  onUpdate: any;
}

const ClientSearchBar: React.FC<IClientSearchBarProps> = ({
  filters,
  onUpdate,
}: IClientSearchBarProps) => {
  const dispatch = useDispatch();
  return (
    <div className="client-search-bar">
      <span className="material-icons">search</span>
      <div className="input-row">
        <input
          placeholder="ID"
          value={filters.id || ''}
          onFocus={() => dispatch(setDisplayed(true))}
          onChange={e => onUpdate({ id: e.target.value })}
        />
      </div>
      <div className="input-row">
        <input
          placeholder="Nom"
          value={filters.nom || ''}
          onFocus={() => dispatch(setDisplayed(true))}
          onChange={e => onUpdate({ nom: e.target.value })}
        />
      </div>
      <div className="input-row">
        <input
          placeholder="Prénom"
          value={filters.prenom || ''}
          onFocus={() => dispatch(setDisplayed(true))}
          onChange={e => onUpdate({ prenom: e.target.value })}
        />
      </div>
      <button type="button" onClick={() => dispatch(setClientAsNew())}>
        <span className="material-icons">add</span>
      </button>
    </div>
  );
};

export default ClientSearchBar;
