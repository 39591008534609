import { authService } from '../../services/authService/auth.service';
import {
  AppDispatch,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LoginError,
  LoginSuccess,
  Logout,
  LOGOUT,
} from './type';

const loginSuccess = (jwt: string, user: any): LoginSuccess => ({
  type: LOGIN_SUCCESS,
  payload: {
    jwt,
    user,
  },
});

const loginError = (err: any): LoginError => ({
  type: LOGIN_ERROR,
  payload: err,
});

export const logout = (): Logout => ({
  type: LOGOUT,
});

export const checkAuth = () => (dispatch: AppDispatch): void => {
  const token = localStorage.getItem('TOKEN');
  if (!token) return;
  authService
    .checkToken(token)
    .then(res => dispatch(loginSuccess(token, res.user)))
    .catch(() => dispatch(loginError("votre token n'est plus valide")));
};

export const login = (login: string, pwd: string) => (
  dispatch: AppDispatch
): void => {
  authService
    .login(login, pwd)
    .then((res: any) => dispatch(loginSuccess(res.jwt, res.user)))
    .catch(err =>
      dispatch(
        loginError(
          err.status && err.status === 400
            ? 'Login ou mot de passe incorrect'
            : "Erreur de connexion, vérifiez votre connexion ou contactez l'administrateur"
        )
      )
    );
};
