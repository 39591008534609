import { BASE_URL } from '../../config/config';
import { authHeader } from '../../core/AuthProvider';

export const getFormes = (label: string) =>
  fetch(`${BASE_URL}/formes?titre_contains=${label}`, {
    method: 'GET',
    headers: authHeader(),
  }).then(res => {
    if (!res.ok) {
      throw {
        status: res.status,
        message: 'Erreur lors de la récupération des symptômes',
      };
    }
    return res.json();
  });

export const createForme = (titre: string) =>
  fetch(`${BASE_URL}/formes`, {
    method: 'POST',
    headers: { ...authHeader(), 'Content-type': 'application/json' },
    body: JSON.stringify({ titre }),
  }).then(res => {
    if (!res.ok) {
      throw {
        status: res.status,
        message: 'Erreur lors de la création du symptôme',
      };
    }
    return res.json();
  });
