import React, { useState } from 'react';
import Client from '../../models/client';
import { deleteClient } from '../../store/client/client.actions';
import { useDispatch } from 'react-redux';

import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: { zIndex: 999 },
};

interface IDelClientButtonProps {
  client: Client;
}

const DelClientButton: React.FC<IDelClientButtonProps> = ({ client }) => {
  const [onConfirm, setOnConfirm] = useState(false);

  const dispatch = useDispatch();

  const delClient = () => {
    if (!client.id) {
      throw new Error('The client does not have an id.');
    }
    closeModal();
    dispatch(deleteClient(client.id));
  };

  const closeModal = () => {
    setOnConfirm(false);
  };

  return (
    <>
      {!onConfirm && (
        <button
          className="btn-danger-outline"
          onClick={() => setOnConfirm(true)}
        >
          Supprimer le client
        </button>
      )}
      <Modal
        isOpen={onConfirm}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Delete client confirmation"
      >
        <span>
          Êtes vous sûr de vouloir supprimer{' '}
          <b>
            {client.nom} {client.prenom}
          </b>{' '}
          ?
        </span>
        <div className="full-width row mt-5">
          <button className="btn-danger push-left" onClick={delClient}>
            Supprimer
          </button>
          <button className="ml-4" onClick={closeModal}>
            Annuler
          </button>
        </div>
      </Modal>
    </>
  );
};

export default DelClientButton;
