import consultationService from '../../services/consultationService/consultation.service';
import { addConsultation, removeConsultation } from '../client/client.actions';
import { Consultation, Preparation } from '../../models/consultation';
import { Dispatch, StoreState } from '../index';

import debounce from 'lodash.debounce';
import { query } from '../../core/AuthProvider';

import { CONSULT_UPDATE_DEBOUNCE_TIME } from '../../config/config';
import { toast } from 'react-hot-toast';
import {
  ADD_FICHIERS,
  AddFichiers,
  ConsultationDispatch,
  DELETE_CONSULTATION_ERROR,
  DELETE_CONSULTATION_STARTED,
  DELETE_CONSULTATION_SUCCESS,
  DeleteConsultationError,
  DeleteConsultationStarted,
  DeleteConsultationSuccess,
  FETCH_CONSULTATION_ERROR,
  FETCH_CONSULTATION_STARTED,
  FetchConsultationError,
  FetchConsultationStarted,
  GET_CONSULTATION_ERROR,
  GET_CONSULTATION_STARTED,
  GET_CONSULTATION_SUCCESS,
  GetConsultationError,
  GetConsultationStarted,
  GetConsultationSuccess,
  SET_PREPARATION_SELECTED,
  SetPreparationSelected,
  UNSELECT_CONSULTATION,
  UnselectConsultation,
  UPDATE_CONSULTATION,
} from './type';
import { toastError } from '../../utils/toastr';

const getConsultationStarted = (): GetConsultationStarted => ({
  type: GET_CONSULTATION_STARTED,
});

const getConsultationSuccess = (
  consultation: Partial<Consultation>
): GetConsultationSuccess => ({
  type: GET_CONSULTATION_SUCCESS,
  payload: consultation,
});

const getConsultationError = (err: any): GetConsultationError => ({
  type: GET_CONSULTATION_ERROR,
  payload: err,
});

export const unselectConsultation = (): UnselectConsultation => ({
  type: UNSELECT_CONSULTATION,
});

const fetchConsultationStarted = (): FetchConsultationStarted => ({
  type: FETCH_CONSULTATION_STARTED,
});

const fetchConsultationError = (error: any): FetchConsultationError => {
  toastError(error.message);
  return {
    type: FETCH_CONSULTATION_ERROR,
    payload: error,
  };
};

export const setPreparationSelected = (
  index: number
): SetPreparationSelected => ({
  type: SET_PREPARATION_SELECTED,
  payload: index,
});

export const addFichiers = (fichiers: any[]): AddFichiers => ({
  type: ADD_FICHIERS,
  payload: fichiers,
});

export const deleteConsultationStarted = (): DeleteConsultationStarted => ({
  type: DELETE_CONSULTATION_STARTED,
});

export const deleteConsultationSuccess = (): DeleteConsultationSuccess => ({
  type: DELETE_CONSULTATION_SUCCESS,
});

export const deleteConsultationError = (err: any): DeleteConsultationError => ({
  type: DELETE_CONSULTATION_ERROR,
  payload: err,
});

export const setConsultationAsNew = (clientId: string) => (
  dispatch: Dispatch
): void => {
  const newConsultation = consultationService.getNewConsultation(clientId);
  dispatch(getConsultationStarted());
  query(consultationService.fetchConsultation(newConsultation))
    .then(consultation => {
      dispatch(
        addConsultation({
          date: consultation.date,
          id: consultation.id,
        })
      );
      dispatch(getConsultationSuccess(consultation));
    })
    .catch(err => dispatch(getConsultationError(err)));
};

export const getConsultation = (id: string) => (
  dispatch: ConsultationDispatch
): void => {
  dispatch(getConsultationStarted());
  query(consultationService.getConsultation(id))
    .then(consultation => dispatch(getConsultationSuccess(consultation)))
    .catch(err => dispatch(getConsultationError(err)));
};

export const updateConsultation = (updates: Partial<Consultation>) => (
  dispatch: ConsultationDispatch,
  getState: () => StoreState
): void => {
  const {
    consultationState: { consultation },
  } = getState();
  if (!consultation || !consultation.id) return;

  debounceUpdate(consultation.id, updates, dispatch);
  dispatch({
    type: UPDATE_CONSULTATION,
    payload: updates,
  });
};

// FIXME Je suis pas sûr que le debounce fonctionne
const debounceUpdate = debounce(
  (
    id: string,
    updates: Partial<Consultation>,
    dispatch: ConsultationDispatch
  ) => {
    dispatch(fetchConsultationStarted());
    query(consultationService.fetchConsultation(updates, id)).catch(err =>
      dispatch(fetchConsultationError(err))
    );
  },
  CONSULT_UPDATE_DEBOUNCE_TIME
);

export const addNewPreparation = () => (
  dispatch: ConsultationDispatch,
  getState: () => StoreState
): void => {
  const {
    consultationState: { consultation },
  } = getState();
  if (!consultation) return;
  const preps: Preparation[] = [
    ...consultation.preparations,
    consultationService.getNewPreparation(),
  ];
  dispatch(updateConsultation({ preparations: preps }));
  dispatch(setPreparationSelected(consultation.preparations.length));
};

export const deletePreparation = (index: number) => (
  dispatch: ConsultationDispatch,
  getState: () => StoreState
): void => {
  const {
    consultationState: { consultation },
  } = getState();
  if (!consultation) return;
  const preparations = [...consultation.preparations];
  preparations.splice(index, 1);
  dispatch(setPreparationSelected(preparations.length - 1));
  dispatch(updateConsultation({ preparations }));
};

export const deleteConsultation = (id: string) => (
  dispatch: Dispatch
): void => {
  dispatch(deleteConsultationStarted());
  query(consultationService.deleteConsultation(id))
    .then(() => {
      dispatch(unselectConsultation());
      dispatch(removeConsultation(id));
      toast.success('Consultation supprimée', { duration: 5000 });
      return dispatch(deleteConsultationSuccess());
    })
    .catch((err: any) => dispatch(deleteConsultationError(err)));
};
