import Client from '../../models/client';
import { ThunkDispatch } from 'redux-thunk';

export interface ClientsState {
  filters: {
    id?: string;
    prenom?: string;
    nom?: string;
  };
  clients: Client[];
  displayed: boolean;
  loading: boolean;
  error: string | null;
}

export type FiltersUpdates = ClientsState['filters'] & {
  [key: string]: string;
};

export const UPDATE_FILTERS = 'UPDATE_FILTERS',
  SET_DISPLAYED_TRUE = 'SET_DISPLAYED_TRUE',
  SET_DISPLAYED_FALSE = 'SET_DISPLAYED_FALSE',
  CLEAR_FILTERS = 'CLEAR_FILTERS',
  FETCH_CLIENTS_STARTED = 'FETCH_CLIENTS_STARTED',
  FETCH_CLIENTS_SUCCESS = 'FETCH_CLIENTS_SUCCESS',
  FETCH_CLIENTS_ERROR = 'FETCH_CLIENTS_ERROR';

export interface UpdateFilters {
  type: typeof UPDATE_FILTERS;
  payload: FiltersUpdates;
}

export interface SetDisplayedTrue {
  type: typeof SET_DISPLAYED_TRUE;
}

export interface SetDisplayedFalse {
  type: typeof SET_DISPLAYED_FALSE;
}

export interface FetchClientsStarted {
  type: typeof FETCH_CLIENTS_STARTED;
}

export interface FetchClientsSuccess {
  type: typeof FETCH_CLIENTS_SUCCESS;
  payload: Client[];
}

export interface FetchClientsError {
  type: typeof FETCH_CLIENTS_ERROR;
  payload: string;
}

export interface ClearFilters {
  type: typeof CLEAR_FILTERS;
}

export type ClientsDispatch = ThunkDispatch<ClientsState, void, ClientsAction>;

export type ClientsAction =
  | UpdateFilters
  | SetDisplayedTrue
  | SetDisplayedFalse
  | FetchClientsStarted
  | FetchClientsSuccess
  | FetchClientsError
  | ClearFilters;
