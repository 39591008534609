import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ClientListPanel from '../../containers/ClientListPanel';
import ConsultationPanel from '../../containers/ConsultationPanel';
import { StoreState } from '../../store';
import { setClientAsNew } from '../../store/client/client.actions';
import './Main.scss';
import ClientPanel from '../../containers/ClientPanel';
import ClientConsultationList from '../../components/ClientConsultationList';
import Client from '../../models/client';
import useToastError from '../../hooks/useToastError';
import useGetClientFromQueryParam from '../../hooks/useGetClientFromQueryParam';

const Main: React.FC = () => {
  const { client, error } = useSelector(
    (state: StoreState) => state.clientState
  );

  const [consultations, setConsultations] = useState<Client['consultations']>(
    client?.consultations || []
  );

  const dispatch = useDispatch();
  useGetClientFromQueryParam();
  useToastError(error);

  useEffect(() => {
    if (client) {
      setConsultations(client.consultations);
    } else {
      setConsultations([]);
    }
  }, [client]);

  return (
    <main>
      <section className="client-side">
        <ClientListPanel />
        {client ? (
          <ClientPanel />
        ) : (
          <div className="full-height full-width column dgrey">
            <div>Veuillez sélectionner un client</div>
            <div>ou</div>
            <button
              className="d-flex align-items-center green"
              onClick={() => dispatch(setClientAsNew())}
            >
              <span className="material-icons">add</span>
              <span>Ajouter un client</span>
            </button>
          </div>
        )}
      </section>
      {client && client.id ? (
        <section className="consultations">
          <ClientConsultationList consultations={consultations} />
        </section>
      ) : null}
      <ConsultationPanel />
    </main>
  );
};

export default Main;
