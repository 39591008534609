import React from 'react';
import Header from './Header';
import Main from './Main';
import './Layout.scss';

const Layout: React.FC = () => {
  return (
    <div className="layout">
      <Header />
      <Main />
    </div>
  );
};

export default Layout;
