import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../store';
import {
  addFichiers,
  addNewPreparation,
  deleteConsultation,
  deletePreparation,
  setConsultationAsNew,
  setPreparationSelected,
  unselectConsultation,
  updateConsultation,
} from '../../store/consultation/consultation.actions';
import SymptomeListPanel from '../SymptomeListPanel';
import MediaGallery from '../../components/MediaGallery';
import { Consultation, Preparation } from '../../models/consultation';
import Client from '../../models/client';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import PosologieSuggester from '../../components/PosologieSuggester';
import FormeSuggester from '../../components/FormeSuggester';
import ModeSuggester from '../../components/ModeSuggester';
import './ConsultationPanel.scss';
import Notes from '../../components/Notes';
import Loader from '../../components/_generic/Loader';
import useGetConsultationFromQueryParam from '../../hooks/useGetConsultationFromQueryParam';
import useToastError from '../../hooks/useToastError';
import { toastError } from '../../utils/toastr';

const ConsultationPanel: React.FC = () => {
  const [displayMediaGallery, setDisplayMediaGallery] = useState<boolean>(
    false
  );
  const [displayNotesLabo, setDisplayNotesLabo] = useState<boolean>(false);
  const [displayDeleteModal, setDisplayDeleteModal] = useState<boolean>(false);
  const [displayExportModal, setDisplayExportModal] = useState<boolean>(false);
  const [numberOfLines, setNumberOfLines] = useState<number>(0);

  const { consultation, preparationSelected, loading, error } = useSelector(
    (state: StoreState) => state.consultationState
  );

  useGetConsultationFromQueryParam();
  useToastError(error);

  const prepTextRef = useRef(null);
  useEffect(() => {
    if (prepTextRef.current) (prepTextRef.current as any).focus();
  }, [preparationSelected]);

  const { client } = useSelector((state: StoreState) => state.clientState);

  const dispatch = useDispatch();

  const preparation = consultation
    ? consultation.preparations[preparationSelected]
    : undefined;

  const onUpdateConsultation = (e: any) => {
    dispatch(updateConsultation({ [e.target.name]: e.target.value }));
  };

  const onUpdatePreparationSelected = (value: Partial<Preparation>) => {
    if (!consultation) return;
    const preparations: Preparation[] = [...consultation.preparations];
    preparations[preparationSelected] = {
      ...preparations[preparationSelected],
      ...value,
    };
    dispatch(updateConsultation({ preparations }));
  };

  useEffect(() => {
    if (prepTextRef && prepTextRef.current) {
      const taLineHeight = 21;
      const taHeight = (prepTextRef.current as any).scrollHeight;
      const numberOfLines = Math.floor(taHeight / taLineHeight);
      setNumberOfLines(numberOfLines);
    }
  }, [consultation?.preparations[preparationSelected]]);

  useEffect(() => {
    setDisplayMediaGallery(false);
    setDisplayNotesLabo(false);
    setDisplayDeleteModal(false);
    setDisplayExportModal(false);
  }, [consultation?.id]);

  /**
   * Check if the consultation selected is one of the current client.
   */
  useEffect(() => {
    if (
      consultation?.id &&
      client &&
      !client?.consultations.find(
        (c: any) => Number(c.id) === Number(consultation.id)
      )
    ) {
      toastError(
        `La consultation ${consultation.id} n'est pas associée au patient [${client.id}] ${client.nom} ${client.prenom}`
      );
      dispatch(unselectConsultation());
    }
  }, [client, consultation]);

  return client ? (
    !loading ? (
      consultation ? (
        <div className="consultation-panel">
          <div className="consultation-panel_header">
            <div className="input-row">
              <label>Date</label>
              <input
                name="date"
                type="date"
                onChange={onUpdateConsultation}
                value={consultation.date}
                autoFocus={!consultation.id}
              />
            </div>
            <div>
              <div className="input-row">
                <label>Plainte principale</label>
                <input
                  name="plainte"
                  onChange={onUpdateConsultation}
                  value={consultation.plainte || ''}
                  autoFocus={!!consultation.id}
                />
              </div>
            </div>
            {consultation.id ? (
              <button
                className="del-consultation-btn"
                onClick={() => {
                  setDisplayDeleteModal(true);
                  setDisplayExportModal(false);
                  setDisplayMediaGallery(false);
                }}
              >
                <span className="material-icons">close</span>
              </button>
            ) : null}
          </div>
          <div className="input-row formule-input">
            <label>Formule principale</label>
            <input
              name="formule"
              onChange={onUpdateConsultation}
              value={consultation.formule || ''}
            />
          </div>
          <div>
            <div style={{ borderBottom: '1px solid #d4d4d4' }}>
              <label>Plainte secondaires</label>
            </div>
            <SymptomeListPanel
              symptomes={consultation.symptomes}
              onUpdate={(symptomes: any) =>
                dispatch(updateConsultation({ symptomes }))
              }
            />
          </div>
          <div className="consultation-panel_buttons">
            <button
              className={!displayMediaGallery ? 'selected' : ''}
              onClick={() => {
                setDisplayMediaGallery(false);
                setDisplayExportModal(false);
                setDisplayDeleteModal(false);
              }}
            >
              Infos
            </button>
            <button
              className={displayMediaGallery ? 'selected' : ''}
              disabled={!consultation.id}
              onClick={() => {
                setDisplayMediaGallery(true);
                setDisplayExportModal(false);
                setDisplayDeleteModal(false);
              }}
            >
              Medias
            </button>
            <button
              className={displayExportModal ? 'selected' : ''}
              disabled={!consultation.id}
              onClick={() => {
                setDisplayMediaGallery(false);
                setDisplayExportModal(true);
              }}
            >
              Exporter
            </button>
          </div>
          {displayMediaGallery ? (
            <MediaGallery
              consultationId={consultation.id as string}
              fichiers={consultation.fichiers}
              onAddFichiers={fichiers => dispatch(addFichiers(fichiers))}
            />
          ) : (
            <div className="consultation-panel_info">
              <div className="preparation">
                <div
                  style={{
                    padding: '1rem 0',
                    display: 'flex',
                  }}
                >
                  <label>Preparation</label>
                  <div className="preparation-header">
                    {consultation.preparations.map((p: any, index: number) => (
                      <div className="row" key={index}>
                        <button
                          className={
                            preparationSelected === index
                              ? 'preparation-button selected'
                              : 'preparation-button'
                          }
                          onClick={() =>
                            dispatch(setPreparationSelected(index))
                          }
                        >
                          <span>{index + 1}</span>
                        </button>
                        {preparationSelected === index ? (
                          <button>
                            <span
                              onClick={() => dispatch(deletePreparation(index))}
                              className="material-icons dgrey"
                            >
                              close
                            </span>
                          </button>
                        ) : null}
                      </div>
                    ))}
                    <button
                      className="preparation-button"
                      onClick={() => dispatch(addNewPreparation())}
                    >
                      +
                    </button>
                  </div>
                </div>
                {preparation ? (
                  <div className="preparation_content">
                    <div className="preparation_ingredient">
                      <div className="line_number">
                        {Array.from(Array(numberOfLines).keys()).map(n => (
                          <div key={n}>{n + 1}.</div>
                        ))}
                      </div>
                      <textarea
                        ref={prepTextRef}
                        name="ingredients"
                        value={(preparation.ingredients as string) || ''}
                        onChange={(e: any) =>
                          onUpdatePreparationSelected({
                            ingredients: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="preparation_info">
                      <div className="input-row">
                        <label>Quantité</label>
                        <input
                          name="quantitee"
                          value={preparation.quantitee || ''}
                          onChange={(e: any) =>
                            onUpdatePreparationSelected({
                              quantitee: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="input-row">
                        <label>Forme</label>
                        <FormeSuggester
                          forme={preparation.forme || ''}
                          onChange={(forme: any) =>
                            onUpdatePreparationSelected({ forme })
                          }
                        />
                      </div>
                      <div className="input-row">
                        <label>Posologie</label>
                        <PosologieSuggester
                          posologie={preparation.posologie || ''}
                          onChange={(posologie: any) =>
                            onUpdatePreparationSelected({ posologie })
                          }
                        />
                      </div>
                    </div>
                    <div className="input-row">
                      <label>Mode de préparation</label>
                      <ModeSuggester
                        mode={preparation.mode || ''}
                        onChange={(mode: any) =>
                          onUpdatePreparationSelected({ mode })
                        }
                      />
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="notes">
                <div
                  className="notes"
                  style={{ padding: '1rem', borderBottom: '1px solid #d4d4d4' }}
                >
                  <label
                    onClick={() => {
                      setDisplayNotesLabo(false);
                      setDisplayExportModal(false);
                      setDisplayDeleteModal(false);
                    }}
                    className={!displayNotesLabo ? 'selected' : ''}
                  >
                    Notes
                  </label>
                  <label
                    onClick={() => {
                      setDisplayNotesLabo(true);
                      setDisplayExportModal(false);
                      setDisplayDeleteModal(false);
                    }}
                    className={displayNotesLabo ? 'selected' : ''}
                  >
                    Notes labo
                  </label>
                </div>
                {displayDeleteModal ? (
                  <div className="full-width full-height column">
                    <div className="column">
                      <div className="dgrey">
                        Êtes-vous sûr de vouloir supprimer cette consultation
                      </div>
                      <div className="row mt-4">
                        <button
                          className="green"
                          onClick={() =>
                            dispatch(
                              deleteConsultation(consultation.id as string)
                            )
                          }
                        >
                          Oui
                        </button>
                        <button
                          className="red ml-6"
                          onClick={() => setDisplayDeleteModal(false)}
                        >
                          Non
                        </button>
                      </div>
                    </div>
                  </div>
                ) : displayExportModal ? (
                  <ExportArea
                    onClose={() => setDisplayExportModal(false)}
                    client={client}
                    consultation={consultation}
                  />
                ) : displayNotesLabo ? (
                  <textarea
                    className="notes"
                    name="notesLabo"
                    autoFocus={true}
                    value={consultation.notesLabo || ''}
                    onChange={onUpdateConsultation}
                  />
                ) : (
                  <Notes
                    value={consultation.notes}
                    consultationId={consultation.id || ''}
                    onChange={(notes: any) =>
                      dispatch(updateConsultation({ notes }))
                    }
                    onAddFichiers={(fichiers: any[]) =>
                      dispatch(addFichiers(fichiers))
                    }
                  />
                )}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="full-width full-height column b-lgrey">
          {client.id ? (
            <div className="column hgrey">
              <span>Sélectionner une consultation</span>
              <span>ou</span>
              <button
                className="green row align-items-center"
                onClick={() =>
                  dispatch(setConsultationAsNew(client.id as string))
                }
              >
                <span className="material-icons">add</span>
                <span className="text-2">
                  Ajouter une nouvelle consultation
                </span>
              </button>
            </div>
          ) : null}
        </div>
      )
    ) : (
      <Loader show={true} />
    )
  ) : null;
};

export default ConsultationPanel;

interface IExportAreaProps {
  consultation: Consultation;
  client: Client;
  onClose: () => void;
}

const ExportArea: React.FC<IExportAreaProps> = ({
  consultation,
  client,
  onClose,
}: IExportAreaProps) => {
  const [parsedExport, setParsedExport] = useState<string>('');
  useEffect(() => {
    const lines = [];

    if (consultation.notesLabo?.length > 0) lines.push(consultation.notesLabo);
    lines.push('');

    lines.push(`${client.nom.toUpperCase()} ${client.prenom}`);
    lines.push('');

    if (client.adresse) {
      if (client.adresse.line1?.trim().length > 0)
        lines.push(client.adresse.line1);
      if (client.adresse.line2?.trim().length > 0)
        lines.push(client.adresse.line2);
      if (client.adresse.line3?.trim().length > 0)
        lines.push(client.adresse.line3);
      if (client.adresse.line4?.trim().length > 0)
        lines.push(client.adresse.line4);
      if (client.adresse.cp?.trim().length > 0) lines.push(client.adresse.cp);
      if (client.adresse.ville?.trim().length > 0)
        lines.push(client.adresse.ville);
      lines.push('');
    }

    if (client.telephone) lines.push(client.telephone);
    if (client.email) lines.push(client.email);
    lines.push('');

    consultation.preparations.map((p, index) => {
      lines.push(`Préparation ${index + 1}`);
      p.ingredients
        .replace(/^\s*[\r\n]/gm, '')
        .split('\n')
        .map((l: string, i) => {
          lines.push(`${i + 1}. ${l}`);
        });
      lines.push(
        `QSP ${p.quantitee ? p.quantitee + ' g,' : ''} ${
          p.forme ? p.forme + ',' : ''
        } ${p.posologie || ''}`
      );
      if (p.mode?.length > 0) lines.push(p.mode);
      lines.push('');
    });

    const string = lines.reduce(
      (acc: string, curr: string) => acc.concat(`${curr} \n`),
      ''
    );
    setParsedExport(string);
  }, [consultation]);

  return (
    <div className="export-area">
      <textarea
        onClick={() => navigator.clipboard.writeText(parsedExport)}
        rows={parsedExport.split('\n').length}
        readOnly
        value={parsedExport || ''}
      />
      <button onClick={onClose}>
        <span className="material-icons">close</span>
      </button>
    </div>
  );
};
