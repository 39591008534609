import React from 'react';
import Client from '../../models/client';
import { formatDate } from '../../utils/format';

interface IClientsListProps {
  clients: Client[];
  errors: null | string;
  onSelect: (id: string) => void;
}

const ClientList: React.FC<IClientsListProps> = ({ clients, onSelect }) => {
  return (
    <div className="clients-list">
      {clients.map((client, index) => (
        <button
          key={index}
          className="clients-list_item"
          onClick={() => onSelect(client.id as string)}
        >
          <div>
            [{client.id}] {client.nom} {client.prenom}
          </div>
          {client.dateNaissance ? (
            <div className="dgrey">{formatDate(client.dateNaissance)}</div>
          ) : (
            ''
          )}
        </button>
      ))}
    </div>
  );
};

export default ClientList;
