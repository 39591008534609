import {
  AppAction,
  AppState,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGOUT,
} from './type';

import { Reducer } from 'redux';

const appReducer: Reducer = (state: AppState, action: AppAction): AppState => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      localStorage.setItem('TOKEN', action.payload.jwt);
      return {
        ...state,
        user: action.payload.user,
        jwt: action.payload.jwt,
        isAuth: true,
        error: undefined,
      };
    case LOGIN_ERROR:
      localStorage.removeItem('TOKEN');
      return {
        ...state,
        isAuth: false,
        jwt: undefined,
        user: undefined,
        error: action.payload,
      };
    case LOGOUT:
      localStorage.removeItem('TOKEN');
      return {
        ...state,
        isAuth: false,
        jwt: undefined,
        user: undefined,
        error: undefined,
      };
    default:
      return { ...state };
  }
};

export default appReducer;
