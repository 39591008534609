import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getConsultation } from '../store/consultation/consultation.actions';
import useQueryParams from './useQueryParams';

const useGetConsultationFromQueryParam = (): void => {
  const params = useQueryParams();
  const dispatch = useDispatch();

  const consultationId = params.get('consultationId');

  useEffect(() => {
    if (consultationId) {
      dispatch(getConsultation(consultationId));
    }
  }, [consultationId]);
};

export default useGetConsultationFromQueryParam;
