import React, { useEffect, useRef } from 'react';
import AddressForm from '../AddressForm';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import SymptomeListPanel from '../../containers/SymptomeListPanel';
import { updateClient } from '../../store/client/client.actions';
import { capitalize } from '../../utils/format';
import DelClientButton from '../DelClientButton';
import Client from '../../models/client';
import './ClientForm.scss';

interface IClientFormProps {
  client: Client;
  edited: boolean;
  onUpdate: (updates: Partial<Client>) => void;
  onFetch: () => void;
}

const ClientForm: React.FC<IClientFormProps> = ({
  client,
  edited,
  onUpdate,
  onFetch,
}: IClientFormProps) => {
  const dispatch = useDispatch();

  const handleOnUpdate = (e: any) => {
    onUpdate({ [e.target.name]: e.target.value });
  };

  const handleAgeUpdate = (e: any) => {
    const age = e.target.value;
    if (age > 0) {
      const dateNaissance = moment()
        .subtract(age, 'years')
        .format('YYYY-MM-DD');
      onUpdate({ age, dateNaissance });
    } else {
      onUpdate({ age });
    }
  };

  const handleBirthdayUpdate = (e: any) => {
    const dateNaissance = moment(e.target.value);
    const age = -dateNaissance.diff(moment(), 'years');
    onUpdate({
      age: age < 150 ? age : 0,
      dateNaissance: dateNaissance.format('YYYY-MM-DD'),
    });
  };

  const handleOnFetch = (e: any) => {
    e.preventDefault();
    onFetch();
  };

  const firstInputRef = useRef(null);

  useEffect(() => {
    if (!client.id && firstInputRef) (firstInputRef.current as any).focus();
  }, [client.id]);

  return (
    <div className="client-form">
      {client.id ? (
        <div className="input-row">
          <label>ID</label>
          <div style={{ padding: '0 0.4rem' }}>P{10000 + client.id}</div>
        </div>
      ) : (
        ''
      )}
      <div className="input-row">
        <label className="required">Nom</label>
        <input
          className="uppercase"
          type="text"
          name="nom"
          value={client.nom || ''}
          onChange={(e: any) => {
            onUpdate({ [e.target.name]: e.target.value.toUpperCase() });
          }}
          autoFocus={!client.id}
          ref={firstInputRef}
        />
      </div>
      <div className="input-row">
        <label className="required">Prenom</label>
        <input
          className="capitalize"
          type="text"
          name="prenom"
          value={client.prenom || ''}
          onChange={(e: any) => {
            onUpdate({ [e.target.name]: capitalize(e.target.value) });
          }}
        />
      </div>
      <div className="row">
        <div className="input-row">
          <label>Date de naissance</label>
          <input
            type="date"
            name="dateNaissance"
            value={client.dateNaissance || ''}
            onChange={handleBirthdayUpdate}
          />
        </div>
        <div className="input-row ml-3">
          <label>Age</label>
          <input
            type="text"
            name="age"
            size={4}
            value={client.age || ''}
            onChange={handleAgeUpdate}
          />
        </div>
        <div className="input-row ml-3">
          <label>Sexe</label>
          <select
            name="sexe"
            value={client.sexe || 'homme'}
            onChange={handleOnUpdate}
          >
            <option value="homme">Homme</option>
            <option value="femme">Femme</option>
          </select>
        </div>
      </div>
      <div>
        <label>Adresse</label>
        <AddressForm
          address={client.adresse || ''}
          onUpdate={adresse => onUpdate({ adresse })}
        />
      </div>

      <div className="input-row">
        <label>Telephone</label>
        <input
          type="text"
          name="telephone"
          value={client.telephone || ''}
          onChange={handleOnUpdate}
        />
      </div>
      <div className="input-row">
        <label>Email</label>
        <input
          type="text"
          name="email"
          value={client.email || ''}
          onChange={handleOnUpdate}
        />
      </div>
      <div className="input-row">
        <label>Profession</label>
        <input
          type="text"
          name="profession"
          value={client.profession || ''}
          onChange={handleOnUpdate}
        />
      </div>
      <div className="input-row">
        <label>Première visite:</label>
        <input
          type="date"
          name="premiereVisite"
          value={client.premiereVisite || ''}
          onChange={handleOnUpdate}
        />
      </div>
      <div className="input-row">
        <label>De la part de</label>
        <input
          type="text"
          name="deLaPart"
          value={client.deLaPart || ''}
          onChange={handleOnUpdate}
        />
      </div>
      <div>
        <label>Plaintes</label>
        <SymptomeListPanel
          symptomes={client.plaintes}
          onUpdate={(plaintes: any) => {
            dispatch(updateClient({ plaintes }));
          }}
        />
      </div>
      <div className="textarea-row">
        <label>Notes</label>
        <textarea
          name="notes"
          rows={5}
          value={client.notes || ''}
          onChange={handleOnUpdate}
        />
      </div>
      <div>
        {edited ? (
          <button
            className="save-button mt-4"
            type="button"
            disabled={!edited || !client.nom}
            onClick={handleOnFetch}
          >
            Sauvegarder
          </button>
        ) : null}
      </div>
      {client.id && (
        <div className="mt-6 d-flex justify-content-flex-end align-items-center">
          <DelClientButton client={client} />
        </div>
      )}
    </div>
  );
};

export default ClientForm;
