import Client from '../../models/client';
import clientService from '../../services/clientService/client.service';
import { setDisplayed } from '../clients/clients.actions';
import { unselectConsultation } from '../consultation/consultation.actions';
import { Consultation } from '../../models/consultation';
import { query } from '../../core/AuthProvider';
import { toast } from 'react-hot-toast';
import {
  ADD_CONSULTATION,
  AddConsultation,
  ClientDispatch,
  DELETE_CLIENT_ERROR,
  DELETE_CLIENT_STARTED,
  DELETE_CLIENT_SUCCESS,
  DeleteClientError,
  DeleteClientStarted,
  DeleteClientSuccess,
  FETCH_CLIENT_ERROR,
  FETCH_CLIENT_STARTED,
  FETCH_CLIENT_SUCCESS,
  FetchClientError,
  FetchClientStarted,
  FetchClientSuccess,
  GET_CLIENT_ERROR,
  GET_CLIENT_STARTED,
  GET_CLIENT_SUCCESS,
  GetClientError,
  GetClientStarted,
  GetClientSuccess,
  REMOVE_CONSULTATION,
  RemoveConsultation,
  SET_CLIENT_AS_NEW,
  UNSELECT_CLIENT,
  UnselectClient,
  UPDATE_CLIENT,
  UpdateClient,
} from './type';
import { Dispatch } from '../index';

export const getClientStarted = (): GetClientStarted => ({
  type: GET_CLIENT_STARTED,
});

export const getClientSuccess = (client: Client): GetClientSuccess => ({
  type: GET_CLIENT_SUCCESS,
  payload: client,
});

export const getClientError = (err: string): GetClientError => ({
  type: GET_CLIENT_ERROR,
  payload: err,
});

const fetchClientStarted = (): FetchClientStarted => ({
  type: FETCH_CLIENT_STARTED,
});

const fetchClientSuccess = (client: Client): FetchClientSuccess => ({
  type: FETCH_CLIENT_SUCCESS,
  payload: client,
});

const fetchClientError = (err: string): FetchClientError => ({
  type: FETCH_CLIENT_ERROR,
  payload: err,
});

export const updateClient = (updates: Partial<Client>): UpdateClient => ({
  type: UPDATE_CLIENT,
  payload: updates,
});

export const unselectClient = (): UnselectClient => ({
  type: UNSELECT_CLIENT,
});

const deleteClientStarted = (): DeleteClientStarted => ({
  type: DELETE_CLIENT_STARTED,
});

const deleteClientSuccess = (): DeleteClientSuccess => ({
  type: DELETE_CLIENT_SUCCESS,
});

const deleteClientError = (err: string): DeleteClientError => ({
  type: DELETE_CLIENT_ERROR,
  payload: err,
});

export const addConsultation = (consultation: {
  id: Consultation['id'];
  date: Consultation['date'];
}): AddConsultation => ({
  type: ADD_CONSULTATION,
  payload: consultation,
});

export const removeConsultation = (id: string): RemoveConsultation => ({
  type: REMOVE_CONSULTATION,
  payload: id,
});

export const setClientAsNew = () => (dispatch: Dispatch): void => {
  dispatch(unselectConsultation());
  dispatch(setDisplayed(false));
  dispatch({
    type: SET_CLIENT_AS_NEW,
  });
};

export const getClient = (id: string) => (dispatch: Dispatch): void => {
  dispatch(getClientStarted());
  dispatch(unselectConsultation());
  query(clientService.getClient(id))
    .then(client => {
      if (!client) {
        console.error(`No client found for id ${id}`);
        dispatch(getClientError(`Pas de patient trouvé pour l'id ${id}`));
      } else {
        dispatch(getClientSuccess(client));
      }
    })
    .catch(err => dispatch(getClientError(err)));
};

export const fetchClient = (updates: Partial<Client>, id?: string) => (
  dispatch: ClientDispatch
): void => {
  dispatch(fetchClientStarted());
  query(clientService.fetchClient(updates, id))
    .then(client => dispatch(fetchClientSuccess(client)))
    .catch(err => dispatch(fetchClientError(err)));
};

export const deleteClient = (id: string) => (
  dispatch: ClientDispatch
): void => {
  dispatch(deleteClientStarted());
  clientService
    .deleteClient(id)
    .then(() => {
      dispatch(deleteClientSuccess());
      toast.success('Patient supprimé', { duration: 5000 });
    })
    .catch(err => {
      console.error(`Error lors de la suppression du client`);
      console.error(err);
      dispatch(deleteClientError(err));
    });
};
