import { Consultation, Preparation } from '../../models/consultation';
import { apolloClient, authHeader } from '../../core/AuthProvider';
import moment from 'moment';
import { BASE_URL } from '../../config/config';
import { gql } from '@apollo/client';

const getNewConsultation = (clientId: string): Consultation => ({
  date: moment().format('YYYY-MM-DD'),
  plainte: '',
  formule: '',
  symptomes: [],
  preparations: [getNewPreparation()],
  fichiers: [],
  notes: '',
  notesLabo: '',
  client: clientId,
});

const getNewPreparation = (): Preparation => ({
  ingredients: '',
  quantitee: '',
  forme: '',
  posologie: '',
  mode: '',
});

const getConsultation = (id: string): Promise<Partial<Consultation>> =>
  fetch(`${BASE_URL}/consultations/${id}`, {
    method: 'GET',
    headers: authHeader(),
  }).then(res => {
    if (!res.ok)
      throw new Error('Erreur lors de la récupération de la consultation');
    return res.json();
  });

const fetchConsultation = (
  consultation: Partial<Consultation>,
  id?: string
): Promise<Consultation> =>
  fetch(`${BASE_URL}/consultations/${id || ''}`, {
    method: id ? 'PUT' : 'POST',
    headers: { ...authHeader(), 'Content-type': 'application/json' },
    body: JSON.stringify(consultation),
  }).then(res => {
    if (!res.ok) throw new Error("Erreur lors de l'envoie de la consultation");
    return res.json();
  });

const deleteConsultation = (id: string): Promise<void> =>
  fetch(`${BASE_URL}/consultations/${id}`, {
    method: 'DELETE',
    headers: { ...authHeader() },
  }).then(res => {
    if (!res.ok)
      throw new Error('Erreur lors de la suppression de la consultation');
    return res.json();
  });

/**
 * Return consultations with plainte matching the search term.
 * @param pPlainte {string} plainte principale
 * @param sPlainte {string} secondary plainte
 * @param dateRange {DateRange}
 * @param pagination {Pagination}
 */
const searchConsultationsByPlainte = (
  pPlainte: string,
  sPlainte?: string,
  dateRange: DateRange = { from: '', to: '' },
  pagination: Pagination = { limit: 10, start: 0 }
): Promise<ConsultationSearch[]> => {
  const pPlainteWhere =
    pPlainte.length >= 0 ? `, plainte_contains: "${pPlainte}"` : '';

  const spPlainteWhere = sPlainte
    ? `, symptomes: { titre_contains: ["${sPlainte}"] }`
    : '';

  const from =
    dateRange.from && dateRange.from.length > 0 ? dateRange.from : null;
  const to = dateRange.to && dateRange.to.length > 0 ? dateRange.to : null;

  const dateFromWhere = from ? `, date_gte: "${from}"` : '';
  const dateToWhere = to ? `, date_lte: "${to}"` : '';

  return apolloClient
    .query({
      query: gql`
      query {
        consultations(
          limit: ${pagination.limit}, 
          start: ${pagination.start}, 
          sort: "date:desc", 
          where: { client_null: "false" ${pPlainteWhere} ${dateFromWhere} ${dateToWhere} ${spPlainteWhere}}
        ) {
          id
          date
          plainte
          symptomes {
            id
            titre
          }
          client {
            id
            nom
            prenom
          }
        }
      }
    `,
    })
    .then(res => res.data.consultations);
};

const consultationService = {
  getNewConsultation,
  getNewPreparation,
  getConsultation,
  fetchConsultation,
  deleteConsultation,
  searchConsultationsByPlainte,
};

export default consultationService;
