import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../store';
import { logout } from '../../store/app/app.actions';
import './Header.scss';
import { unselectConsultation } from '../../store/consultation/consultation.actions';
import { unselectClient } from '../../store/client/client.actions';
import ConsultationSearch from '../../components/_generic/ConsultationSearch';
import { openInNewTab } from '../../utils/navigation';

const Header: React.FC = () => {
  const { user } = useSelector((state: StoreState) => state.appState);
  const dispatch = useDispatch();

  const resetApp = (): void => {
    dispatch(unselectConsultation());
    dispatch(unselectClient());
  };

  const handleConsultationSelected = (consultation: ConsultationSearch) => {
    const queryParams = [
      { key: 'clientId', value: consultation.client.id + '' },
      { key: 'consultationId', value: consultation.id + '' },
    ];
    openInNewTab(queryParams);
  };

  return (
    <header>
      <b className="logo clickable" onClick={resetApp}>
        CV-CONSULT
      </b>
      <ConsultationSearch onConsultationSelected={handleConsultationSelected} />
      <div className="row align-items-center gapped dgrey">
        <span className="material-icons">face</span>
        <div className="mr-2">{user?.username}</div>
        <button className="logout-button" onClick={() => dispatch(logout())}>
          <span className="material-icons">power_settings_new</span>{' '}
          <span>Déconnexion</span>
        </button>
      </div>
    </header>
  );
};

export default Header;
