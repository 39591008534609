import React from 'react';
import './App.css';
import { AuthProvider } from './core/AuthProvider';
import Layout from './layout';

const App = () => {
  return (
    <AuthProvider>
      <Layout />
    </AuthProvider>
  );
};

export default App;
