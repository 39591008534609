import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import consultationService from '../../../services/consultationService/consultation.service';
import Highlighter from 'react-highlight-words';
import debounce from 'lodash.debounce';
import { toastError } from '../../../utils/toastr';
import Loader from '../Loader';
import DateRangeSelect from '../DateRangeSelect';
import './ConsultationSearch.scss';

/**
 * A component with 4 inputs to search consultations by: plainte principale, plainte secondaire and date.
 * @param onConsultationSelected {Function} callback called when a consultation is clicked
 */
const ConsultationSearch: FC<IConsultationSearch> = ({
  onConsultationSelected,
}: IConsultationSearch): ReactElement => {
  const RESULT_PER_PAGE = 30;

  const [consultations, setConsultations] = useState<ConsultationSearch[]>([]);
  /** Plainte principale search terms */
  const [ppSearchTerms, setPpSearchTerms] = useState<string>('');
  /** Secondary plainte search terms */
  const [spSearchTerms, setSpSearchTerms] = useState<string>('');
  const [dateRange, setDateRange] = useState<DateRange>({ from: '', to: '' });
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [endPage, setEndPage] = useState<boolean>(false);

  const scroller = useRef<HTMLDivElement>(null);

  useEffect(() => {
    searchConsultations();
  }, [ppSearchTerms, spSearchTerms, dateRange, page]);

  useEffect(() => {
    if (scroller && scroller.current) {
      scroller.current.addEventListener('scroll', handleScroll);
      return function cleanup() {
        scroller.current?.removeEventListener('scroll', handleScroll);
      };
    }
  });

  const handlePpSearchChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setConsultations([]);
    setPage(0);
    setEndPage(false);
    setPpSearchTerms(e.target.value);
  };

  const handleSpSearchChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setConsultations([]);
    setPage(0);
    setEndPage(false);
    setSpSearchTerms(e.target.value);
  };

  const handleDateRangeChange = (newDateRange: DateRange) => {
    setConsultations([]);
    setPage(0);
    setEndPage(false);
    setDateRange(newDateRange);
  };

  const handleConsultationClicked = (
    consultation: ConsultationSearch
  ): void => {
    onConsultationSelected(consultation);
  };

  /**
   * Fetch more result on scrolling.
   */
  const handleScroll = () => {
    if (!(scroller && scroller.current)) return;
    const s = scroller.current;
    if (
      Math.ceil(s.scrollTop + s.offsetHeight) < s.scrollHeight ||
      isFetching ||
      endPage
    ) {
      return;
    }
    setIsFetching(true);
    setTimeout(() => {
      setPage(page + 1);
    }, 200);
  };

  /**
   * Clear all the inputs.
   */
  const clear = () => {
    setPpSearchTerms('');
    setSpSearchTerms('');
    setConsultations([]);
    setDateRange({ from: '', to: '' });
    setPage(0);
    setEndPage(false);
    setIsFetching(false);
  };

  /**
   * Search consultations where plainte match the terms and the date is between the date range.
   */
  const searchConsultations = useCallback(
    debounce(() => {
      if (
        ppSearchTerms.length <= 1 &&
        spSearchTerms.length <= 1 &&
        dateRange.from?.length === 0 &&
        dateRange.to?.length === 0
      )
        return;
      setIsFetching(true);
      consultationService
        .searchConsultationsByPlainte(ppSearchTerms, spSearchTerms, dateRange, {
          limit: RESULT_PER_PAGE,
          start: page * RESULT_PER_PAGE,
        })
        .then(res => {
          if (res) {
            if (res.length < RESULT_PER_PAGE) {
              setEndPage(true);
            }
            setConsultations([...consultations, ...res]);
          }
        })
        .catch(err => toastError(err))
        .finally(() => setIsFetching(false));
    }, 500),
    [consultations, isFetching, ppSearchTerms, dateRange, page]
  );

  /**
   * Whether the result section is expanded or not.
   */
  const expanded =
    ppSearchTerms.length >= 2 ||
    spSearchTerms.length >= 2 ||
    dateRange.from.length > 0 ||
    dateRange.to.length > 0;

  /**
   * Whether we should display No Result or not.
   */
  const noResult = expanded && consultations.length === 0 && !isFetching;

  return (
    <div className={'input-container ' + (expanded ? 'expanded' : '')}>
      {!isFetching && (
        <span className="material-icons search-icon">search</span>
      )}
      <Loader show={isFetching} size={20} fullWidth={false} />
      {/* Plainte principale input */}
      <input
        type="text"
        placeholder="Rechercher une consultation par plainte principale"
        className="input-main-plainte"
        onChange={handlePpSearchChange}
        value={ppSearchTerms}
      />
      {/* Plainte secondaire input */}
      <input
        type="text"
        placeholder="plainte secondaire"
        className="input-secondary-plainte"
        onChange={handleSpSearchChange}
        value={spSearchTerms}
      />
      {/* Date range inputs*/}
      <DateRangeSelect dateRange={dateRange} onChange={handleDateRangeChange} />
      {/* Clear button */}
      <div className="clear-icon-wrapper clickable" onClick={clear}>
        <span className="material-icons">clear</span>
      </div>

      <div className={'consultation-container ' + (expanded ? 'expanded' : '')}>
        {noResult && (
          <div className="py-2 px-4 dgrey">Pas de consultation trouvée.</div>
        )}
        {/* Search Result */}
        <div className="consultations-container-content" ref={scroller}>
          {consultations.map((c: ConsultationSearch) => (
            <div
              key={c.id}
              className="consultation clickable"
              onClick={() => handleConsultationClicked(c)}
            >
              <div>
                <Highlighter
                  highlightClassName="highlight"
                  searchWords={[ppSearchTerms]}
                  autoEscape={true}
                  textToHighlight={c.plainte}
                />
                <div className="secondary-plainte">
                  {c.symptomes &&
                    c.symptomes.map(s => (
                      <Highlighter
                        key={s.id}
                        highlightClassName="highlight"
                        searchWords={[spSearchTerms]}
                        autoEscape={true}
                        textToHighlight={s.titre + ', '}
                      />
                    ))}
                </div>
              </div>

              <div className="client-date">
                <span>
                  {c.client?.nom} {c.client?.prenom}
                </span>
                <span>le {c.date}</span>
              </div>
            </div>
          ))}
          <div className="px-2 py-2 dgrey text-center">
            {endPage && consultations.length > 0 && (
              <span>Fin des résultats.</span>
            )}
          </div>
        </div>
        {/* Loader */}
        {isFetching && (
          <div className="pb-4">
            <Loader show={isFetching} />
          </div>
        )}
        {/* Result count */}
        <div className="consultations-container-footer">
          (<b>{consultations.length}</b>){' '}
          {consultations.length > 1
            ? 'consultations trouvées'
            : 'consultation trouvée'}
        </div>
      </div>
    </div>
  );
};

export default ConsultationSearch;
