import Client from '../../models/client';
import clientService from '../../services/clientService/client.service';
import {
  CLEAR_FILTERS,
  ClearFilters,
  ClientsDispatch,
  ClientsState,
  FETCH_CLIENTS_ERROR,
  FETCH_CLIENTS_STARTED,
  FETCH_CLIENTS_SUCCESS,
  FetchClientsError,
  FetchClientsStarted,
  FetchClientsSuccess,
  FiltersUpdates,
  SET_DISPLAYED_FALSE,
  SET_DISPLAYED_TRUE,
  UPDATE_FILTERS,
} from './type';
import { query } from '../../core/AuthProvider';
import { Dispatch, StoreState } from '../index';

export const fetchClientsStarted = (): FetchClientsStarted => ({
  type: FETCH_CLIENTS_STARTED,
});

export const fetchClientsSuccess = (
  clients: Client[]
): FetchClientsSuccess => ({
  type: FETCH_CLIENTS_SUCCESS,
  payload: clients,
});

export const fetchClientsError = (error: string): FetchClientsError => ({
  type: FETCH_CLIENTS_ERROR,
  payload: error,
});

export const clearFilters = (): ClearFilters => ({
  type: CLEAR_FILTERS,
});

export const fetchClients = (filters: ClientsState['filters']) => (
  dispatch: ClientsDispatch
): void => {
  dispatch(fetchClientsStarted());
  query(clientService.getClients(filters))
    .then(clients => {
      dispatch(fetchClientsSuccess(clients));
    })
    .catch(err => dispatch(fetchClientsError(err)));
};

export const updateFilters = (updates: FiltersUpdates) => (
  dispatch: Dispatch,
  getState: () => ClientsState
): void => {
  const { filters } = getState();

  const upd = { ...filters, ...updates } as FiltersUpdates;

  if (upd.id || upd.nom || upd.prenom) {
    dispatch(fetchClients({ ...filters, ...upd }));
  } else {
    dispatch(setDisplayed(false));
  }

  dispatch({
    type: UPDATE_FILTERS,
    payload: updates,
  });
};

export const setDisplayed = (displayed: boolean) => (
  dispatch: Dispatch,
  getState: () => StoreState
): void => {
  const {
    clientsState: { displayed: disp, filters },
  } = getState();
  if (!disp && displayed && (filters.nom || filters.prenom)) {
    dispatch(fetchClients(filters));
  }
  dispatch({
    type: displayed ? SET_DISPLAYED_TRUE : SET_DISPLAYED_FALSE,
  });
};
