import { Consultation } from '../../models/consultation';
import { ThunkDispatch } from 'redux-thunk';
import { StoreState } from '../index';

export interface ConsultationState {
  consultation?: Consultation;
  preparationSelected: number;
  loading: boolean;
  error?: string;
}

export const GET_CONSULTATION_STARTED = 'GET_CONSULTATION_STARTED',
  GET_CONSULTATION_SUCCESS = 'GET_CONSULTATION_SUCCESS',
  GET_CONSULTATION_ERROR = 'GET_CONSULTATION_ERROR',
  UNSELECT_CONSULTATION = 'UNSELECT_CONSULTATION',
  UPDATE_CONSULTATION = 'UPDATE_CONSULTATION',
  FETCH_CONSULTATION_STARTED = 'FETCH_CONSULTATION_STARTED',
  FETCH_CONSULTATION_SUCCESS = 'FETCH_CONSULTATION_SUCCESS',
  FETCH_CONSULTATION_ERROR = 'FETCH_CONSULTATION_ERROR',
  SET_PREPARATION_SELECTED = 'SET_PREPARATION_SELECTED',
  DELETE_PREPARATION = 'DELETE_PREPARATION',
  ADD_FICHIERS = 'ADD_FICHIERS',
  DELETE_CONSULTATION_STARTED = 'DELETE_CONSULTATION_STARTED',
  DELETE_CONSULTATION_SUCCESS = 'DELETE_CONSULTATION_SUCCESS',
  DELETE_CONSULTATION_ERROR = 'DELETE_CONSULTATION_ERROR';

export interface GetConsultationStarted {
  type: typeof GET_CONSULTATION_STARTED;
}

export interface GetConsultationSuccess {
  type: typeof GET_CONSULTATION_SUCCESS;
  payload: Partial<Consultation>;
}

export interface GetConsultationError {
  type: typeof GET_CONSULTATION_ERROR;
  payload: any;
}

export interface UnselectConsultation {
  type: typeof UNSELECT_CONSULTATION;
}

export interface UpdateConsultation {
  type: typeof UPDATE_CONSULTATION;
  payload: Partial<Consultation>;
}

export interface FetchConsultationStarted {
  type: typeof FETCH_CONSULTATION_STARTED;
}

export interface FetchConsultationSuccess {
  type: typeof FETCH_CONSULTATION_SUCCESS;
  payload: Consultation;
}

export interface FetchConsultationError {
  type: typeof FETCH_CONSULTATION_ERROR;
  payload: any;
}

export interface SetPreparationSelected {
  type: typeof SET_PREPARATION_SELECTED;
  payload: number;
}

export interface DeletePreparation {
  type: typeof DELETE_PREPARATION;
  payload: number;
}

export interface AddFichiers {
  type: typeof ADD_FICHIERS;
  payload: any[];
}

export interface DeleteConsultationStarted {
  type: typeof DELETE_CONSULTATION_STARTED;
}

export interface DeleteConsultationSuccess {
  type: typeof DELETE_CONSULTATION_SUCCESS;
}

export interface DeleteConsultationError {
  type: typeof DELETE_CONSULTATION_ERROR;
  payload: any;
}

export type ConsultationDispatch = ThunkDispatch<
  StoreState,
  void,
  ConsultationAction
>;

export type ConsultationAction =
  | GetConsultationStarted
  | GetConsultationSuccess
  | GetConsultationError
  | UnselectConsultation
  | UpdateConsultation
  | FetchConsultationStarted
  | FetchConsultationSuccess
  | FetchConsultationError
  | SetPreparationSelected
  | DeletePreparation
  | AddFichiers
  | DeleteConsultationStarted
  | DeleteConsultationSuccess
  | DeleteConsultationError;
