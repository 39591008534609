import './DropZone.scss';
import React, { useEffect, useRef, useState } from 'react';
import { authHeader } from '../../core/AuthProvider';
import axios from 'axios';
import { BASE_URL } from '../../config/config';

const validateFile = (file: File) => {
  const validTypes = [
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/gif',
    'image/x-icon',
  ];
  return validTypes.indexOf(file.type) !== -1;
};

const fileSize = (size: number) => {
  if (size === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(size) / Math.log(k));
  return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

const fileType = (fileName: string) => {
  return (
    fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) ||
    fileName
  );
};

const calculedPercent = (value: number, total: number) =>
  Math.round((value * 100) / total);

interface IDropZoneProps {
  consultationId: string;
  onAddFichiers: (fichiers: any) => void;
}

const DropZone: React.FC<IDropZoneProps> = ({
  consultationId,
  onAddFichiers,
}: IDropZoneProps) => {
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [validFiles, setValidFiles] = useState<any[]>([]);

  const dragOver = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
  };

  const dragEnter = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
  };

  const dragLeave = (e: React.DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
  };

  const fileDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFiles(Array.from(files));
    }
  };

  const onRemoveFile = (name: string) => {
    const validFileIndex = validFiles.findIndex(e => e.name === name);
    validFiles.splice(validFileIndex, 1);
    setValidFiles([...validFiles]);

    const selectedFileIndex = selectedFiles.findIndex(e => e.name === name);
    selectedFiles.splice(selectedFileIndex, 1);
    setSelectedFiles([...selectedFiles]);
  };

  const handleFiles = (files: any[]) => {
    for (let i = 0; i < files.length; i++) {
      if (validateFile(files[i])) {
        setSelectedFiles(prevArray => [...prevArray, files[i]]);
      } else {
        files[i]['invalid'] = true;
        setSelectedFiles((prevArray: any[]) => [...prevArray, files[i]]);
        setErrorMessage('File type not permitted');
      }
    }
  };

  const fileInputRef = useRef<any>(null);
  const fileInputClicked = () => {
    if (fileInputRef && fileInputRef.current)
      (fileInputRef.current as any).click();
  };

  const filesSelected = (e: any) => {
    handleFiles(e.target.files);
  };

  const uploadFiles = () => {
    Array.from(validFiles).map(file => {
      const formData = new FormData();

      formData.append('files', file);
      formData.append('ref', 'consultations');
      formData.append('refId', consultationId);
      formData.append('field', 'fichiers');

      axios
        .post(BASE_URL + '/upload', formData, {
          headers: {
            ...authHeader(),
          },
          onUploadProgress: (progress: any) => {
            const index = validFiles.findIndex(f => f.name === file.name);
            const updatedFiles = [...validFiles];
            updatedFiles[index].uploadProgress = calculedPercent(
              progress.total,
              progress.loaded
            );
            setValidFiles(updatedFiles);
          },
        })
        .then(res => {
          //setUploadedFiles([...uploadedFiles, file]);
          onAddFichiers(res.data);
          onRemoveFile(file.name);
        })
        .catch(err => console.log(err));
    });
  };

  useEffect(() => {
    const filteredArray = selectedFiles.reduce((file, current) => {
      const x = file.find((item: any) => item.name === current.name);
      if (!x) {
        return file.concat([current]);
      } else {
        return file;
      }
    }, []);
    setValidFiles([...filteredArray]);
  }, [selectedFiles]);

  return (
    <div className="drop-zone">
      <label
        className="row"
        style={{ padding: '1rem', borderBottom: '1px solid #d4d4d4' }}
      >
        <span>Upload</span>
      </label>
      <div style={{ padding: '1rem' }}>
        <div
          className="drop-zone-container"
          onDragOver={dragOver}
          onDragEnter={dragEnter}
          onDragLeave={dragLeave}
          onDrop={fileDrop}
          onClick={fileInputClicked}
        >
          <div className="drop-message">
            <span className="material-icons">upload</span>
            <div>Glissez des fichiers ici ou cliquez pour upload</div>
          </div>
          <input
            ref={fileInputRef}
            className="file-input"
            type="file"
            name="files"
            multiple
            onChange={filesSelected}
          />
        </div>
      </div>
      <div className="file-display-container">
        {validFiles.map((data, i) => (
          <FileRow file={data} onRemoveFile={onRemoveFile} key={i} />
        ))}
      </div>
      {validFiles.length > 0 ? (
        <button className="upload-button" onClick={() => uploadFiles()}>
          Sauvegarder les fichiers
        </button>
      ) : null}
    </div>
  );
};

const FileRow = ({ file, onRemoveFile, status }: any) => {
  const imgContainerRef = useRef(null);

  useEffect(() => {
    const reader = new FileReader();
    if (imgContainerRef && imgContainerRef.current) {
      (imgContainerRef.current as any).style.display = 'block';
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        (imgContainerRef.current as any).style.backgroundImage = `url(${
          (e.target as any).result
        })`;
      };
    }
  }, []);

  return (
    <div className="file-status-bar">
      <div className="file-status-bar_content">
        <div ref={imgContainerRef} className="file-type-logo">
          <div className="file-type">{fileType(file.name)}</div>
        </div>

        <span className={`file-name ${file.invalid ? 'file-error' : ''}`}>
          {file.name}
        </span>
        <span className="file-size">({fileSize(file.size)})</span>
        {onRemoveFile ? (
          <div className="file-remove" onClick={() => onRemoveFile(file.name)}>
            X
          </div>
        ) : null}
        {status === 'uploaded' ? <div>Uploaded</div> : null}
      </div>
      <div
        className="file-upload-bar"
        style={{ width: `${file.uploadProgress || 0}%` }}
      />
    </div>
  );
};

export default DropZone;
