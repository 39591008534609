import { Reducer } from 'redux';
import {
  CLEAR_FILTERS,
  FETCH_CLIENTS_ERROR,
  FETCH_CLIENTS_STARTED,
  FETCH_CLIENTS_SUCCESS,
  SET_DISPLAYED_FALSE,
  SET_DISPLAYED_TRUE,
  UPDATE_FILTERS,
  ClientsState,
  ClientsAction,
} from './type';

const clientsReducer: Reducer = (
  state: ClientsState,
  action: ClientsAction
): ClientsState => {
  switch (action.type) {
    case UPDATE_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };
    case SET_DISPLAYED_TRUE:
      return { ...state, displayed: true };
    case SET_DISPLAYED_FALSE:
      return { ...state, displayed: false, clients: [] };
    case CLEAR_FILTERS:
      return { ...state, filters: {} };
    case FETCH_CLIENTS_STARTED:
      return { ...state, loading: true };
    case FETCH_CLIENTS_SUCCESS:
      return {
        ...state,
        clients: action.payload,
        error: null,
        loading: false,
        displayed: true,
      };
    case FETCH_CLIENTS_ERROR:
      return { ...state, clients: [], error: action.payload, loading: false };
    default:
      return { ...state };
  }
};

export default clientsReducer;
