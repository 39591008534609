import React from 'react';
import ClientForm from '../../components/ClientForm';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../store';
import { fetchClient, updateClient } from '../../store/client/client.actions';
import Client from '../../models/client';
import './ClientPanel.scss';
import Loader from '../../components/_generic/Loader';

const ClientPanel: React.FC = () => {
  const { client, edited, updates, loading } = useSelector(
    (state: StoreState) => state.clientState
  );

  const dispatch = useDispatch();

  const clientUpdated: Client = { ...client, ...updates } as Client;

  return (
    <div className="client-panel">
      {!loading && (
        <ClientForm
          client={clientUpdated}
          edited={edited}
          onFetch={() =>
            dispatch(fetchClient({ ...updates } || {}, client?.id))
          }
          onUpdate={updates => dispatch(updateClient(updates))}
        />
      )}
      <Loader show={loading} />
    </div>
  );
};

export default ClientPanel;
