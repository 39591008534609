import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../config/config';
import { authHeader } from '../../core/AuthProvider';
import { Editor } from 'react-draft-wysiwyg';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { draftjsToMd, mdToDraftjs } from 'draftjs-md-converter';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styles from './Notes.module.scss';
import debounce from 'lodash.debounce';

interface INotesProps {
  consultationId: string;
  value: any;
  onChange: (notes: any) => void;
  onAddFichiers: (fichiers: any[]) => void;
}

const Notes: React.FC<INotesProps> = ({
  consultationId,
  value,
  onChange,
  onAddFichiers,
}: INotesProps) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    const rawData = mdToDraftjs(value);
    const contentState = convertFromRaw(rawData);
    setEditorState(EditorState.createWithContent(contentState));
  }, [consultationId]);

  const handleOnChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);
    updateNotes(newEditorState);
  };

  const updateNotes = useCallback(
    debounce(newEditorState => {
      const content = newEditorState.getCurrentContent();
      const rawObject = convertToRaw(content);
      onChange(draftjsToMd(rawObject));
    }, 500),
    [editorState]
  );

  const uploadImageCallBack = (file: any) => {
    if (!consultationId) return;
    const formData = new FormData();

    formData.append('files', file);
    formData.append('ref', 'consultations');
    formData.append('refId', consultationId);
    formData.append('field', 'fichiers');

    return axios
      .post(BASE_URL + '/upload', formData, {
        headers: {
          ...authHeader(),
        },
      })
      .then(res => {
        onAddFichiers(res.data);
        return { data: { link: res.data[0].url } };
      })
      .catch(err => console.log(err));
  };

  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={handleOnChange}
      toolbarClassName={styles.toolbar}
      wrapperClassName={styles.wrapper}
      editorClassName={styles.editor}
      toolbar={{
        inline: { inDropdown: true },
        list: { inDropdown: true },
        textAlign: { inDropdown: true },
        link: { inDropdown: true },
        history: { inDropdown: true },
        image: {
          uploadCallback: uploadImageCallBack,
          previewImage: true,
        },
      }}
    />
  );
};

export default Notes;
