import ReactLoaderSpinner from 'react-loader-spinner';
import './Loader.scss';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import React, { ReactElement } from 'react';

const loaderColor = '#282c34';

type LoaderProps = {
  show: boolean;
  color?: string;
  size?: number;
  fullWidth?: boolean;
};

const Loader: React.FC<LoaderProps> = ({
  show,
  color = loaderColor,
  size = 50,
  fullWidth = true,
}): ReactElement => {
  return (
    <div className={'loader-wrapper ' + (fullWidth ? 'full-width' : '')}>
      <ReactLoaderSpinner
        type="TailSpin"
        visible={show}
        color={color}
        height={size}
        width={size}
      />
    </div>
  );
};

export default Loader;
