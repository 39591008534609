import React from 'react';
import SymptomeSuggester from '../../components/SymptomeSuggester';
import Symptome from '../../models/symptome';
import './SymptomeListPanel.scss';

const SymptomeListPanel: React.FC<any> = props => {
  const handleOnAddSymptome = (symptome: Symptome) => {
    if (props.symptomes.findIndex((s: Symptome) => s.id === symptome.id) < 0)
      props.onUpdate([...props.symptomes, symptome]);
  };

  const handleOnRemoveSymptome = (symptome: Symptome) => {
    const index = props.symptomes.findIndex(
      (s: Symptome) => s.id === symptome.id
    );
    if (index >= 0) {
      const symptomes = [...props.symptomes];
      symptomes.splice(index, 1);
      props.onUpdate([...symptomes]);
    }
  };

  return (
    <div className="symptomes-panel">
      <SymptomeSuggester onAddSymptome={handleOnAddSymptome} />
      {props.symptomes.map((s: Symptome) => (
        <div
          key={s.id}
          className="symptome-item"
          onClick={() => handleOnRemoveSymptome(s)}
        >
          <span>{s.titre}</span>
          <span className="material-icons">close</span>
        </div>
      ))}
    </div>
  );
};

export default SymptomeListPanel;
