import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk, { ThunkDispatch } from 'redux-thunk';

import { AppAction, AppState } from './app/type';
import { ClientAction, ClientState } from './client/type';
import { ClientsAction, ClientsState } from './clients/type';
import { ConsultationAction, ConsultationState } from './consultation/type';
import appReducer from './app/app.reducers';
import clientReducer from './client/client.reducers';
import clientsReducer from './clients/clients.reducer';
import consultationReducer from './consultation/consultation.reducer';

export interface StoreState {
  appState: AppState;
  clientsState: ClientsState;
  clientState: ClientState;
  consultationState: ConsultationState;
}

export type Dispatch = ThunkDispatch<
  StoreState,
  void,
  AppAction | ClientAction | ClientsAction | ConsultationAction
>;

const initialState: StoreState = {
  appState: {
    isAuth: false,
  },
  clientsState: {
    filters: {},
    clients: [],
    loading: false,
    displayed: false,
    error: null,
  },
  consultationState: {
    consultation: undefined,
    preparationSelected: 0,
    loading: false,
  },
  clientState: {
    client: undefined,
    edited: false,
    loading: false,
    fetching: false,
    error: undefined,
  },
};

const rootReducer = combineReducers<StoreState>({
  appState: appReducer,
  clientsState: clientsReducer,
  clientState: clientReducer,
  consultationState: consultationReducer,
});

export const store = createStore(
  rootReducer,
  initialState,
  applyMiddleware(thunk)
);
