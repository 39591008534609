import React, { useEffect, useRef, useState } from 'react';
import { query } from '../../core/AuthProvider';
import { createMode, getModes } from '../../services/modeService/mode.service';
import Suggestion from '../../types/suggestion';
import { Preparation } from '../../models/consultation';
import useClickOutside from '../../hooks/useClickOutside';

interface IModeSuggesterProps {
  mode: string;
  onChange: (mode: Preparation['mode']) => void;
}

const ModeSuggester: React.FC<IModeSuggesterProps> = ({
  mode,
  onChange,
}: IModeSuggesterProps) => {
  const inputRef = useRef(null);
  useClickOutside(inputRef, () => setDisplaySuggestions(false));
  const [searchField, setSearchField] = useState<string>(mode || '');
  const [displaySuggestions, setDisplaySuggestions] = useState<boolean>(false);
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);

  useEffect(() => {
    setSearchField(mode);
  }, [mode]);

  const handleOnChange = (suggestion: Suggestion) => {
    onChange(suggestion.titre);
    setSearchField(suggestion.titre);
    setSuggestions([]);
    setDisplaySuggestions(false);
  };

  const handleCreate = (e: any) => {
    e.preventDefault();
    if (searchField && searchField.length > 0) {
      query(createMode(searchField))
        .then((res: Suggestion) => handleOnChange(res))
        .catch(err => console.log(err));
    }
  };

  const handleSuggestions = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const searchField = e.target.value;
    setSearchField(searchField);
    if (searchField && searchField.length > 0)
      query(getModes(searchField))
        .then(suggestions => {
          setSuggestions(suggestions);
          setDisplaySuggestions(true);
        })
        .catch(err => console.log(err));
    else setSuggestions([]);
  };

  const handleOnFocus = () => {
    setDisplaySuggestions(true);
  };

  const handleOnBlur = () => {
    setDisplaySuggestions(false);
    setSearchField(mode);
    setSuggestions([]);
  };

  return (
    <div className="mode-suggester" onFocus={handleOnFocus} ref={inputRef}>
      <form>
        <input
          className={mode !== searchField ? 'red' : ''}
          value={searchField}
          onChange={handleSuggestions}
        />
        {searchField && searchField.length > 0 && mode !== searchField ? (
          <button type="submit" onClick={handleCreate}>
            <span className="material-icons">check</span>
          </button>
        ) : null}
      </form>
      <div style={{ position: 'relative' }}>
        {displaySuggestions ? (
          <div
            className="mode-suggestions"
            style={{ marginTop: -(suggestions.length - 1) + 'rem' }}
          >
            {suggestions.map((suggestion, index) => (
              <button
                key={`mode-${index}`}
                className="mode-suggestion"
                onClick={() => handleOnChange(suggestion)}
              >
                {suggestion.titre}
              </button>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ModeSuggester;
