import { BASE_URL } from '../../config/config';
import { authHeader } from '../../core/AuthProvider';
import Suggestion from '../../types/suggestion';

export const getPosologies = (label: string): Promise<Suggestion[]> =>
  fetch(`${BASE_URL}/posologies?titre_contains=${label}`, {
    method: 'GET',
    headers: authHeader(),
  }).then(res => {
    if (!res.ok) {
      throw {
        status: res.status,
        message: 'Erreur lors de la récupération des posologies',
      };
    }
    return res.json();
  });

export const createPosologie = (titre: string) =>
  fetch(`${BASE_URL}/posologies`, {
    method: 'POST',
    headers: { ...authHeader(), 'Content-type': 'application/json' },
    body: JSON.stringify({ titre }),
  }).then(res => {
    if (!res.ok) {
      throw {
        status: res.status,
        message: 'Erreur lors de la création du symptôme',
      };
    }
    return res.json();
  });
