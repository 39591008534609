import User from '../../models/user';
import { BASE_URL } from '../../config/config';

const login = (login: string, psw: string): Promise<User> =>
  fetch(`${BASE_URL}/auth/local`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify({
      identifier: login,
      password: psw,
    }),
  }).then(res => {
    if (!res.ok)
      throw {
        status: res.status,
        message: res.json(),
      };
    return res.json();
  });

const checkToken = (token: string): Promise<any> =>
  fetch(`${BASE_URL}/token/decrypt`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify({
      token,
    }),
  }).then(res => {
    if (!res.ok)
      throw {
        status: res.status,
        message: res.json(),
      };
    return res.json();
  });

export const authService = { login, checkToken };
