import { BASE_URL } from '../../config/config';
import { authHeader } from '../../core/AuthProvider';
import Symptome from '../../models/symptome';

export const getSymptomes = (label: string) =>
  fetch(`${BASE_URL}/symptomes?titre_contains=${label}`, {
    method: 'GET',
    headers: authHeader(),
  }).then(res => {
    if (!res.ok) {
      throw {
        status: res.status,
        message: 'Erreur lors de la récupération des symptômes',
      };
    }
    return res.json();
  });

/**
 * Get a single symptome by its exact title.
 * @param title {string}
 */
export const getSymptomeByTitle = async (
  title: string
): Promise<Symptome[]> => {
  const method = 'GET';
  const headers = authHeader();
  const options = { method, headers };
  const queryParams = `titre_eq=${title}`;
  const res = await fetch(`${BASE_URL}/symptomes?${queryParams}`, options);
  return res.json();
};

export const createSymptome = (titre: string) =>
  fetch(`${BASE_URL}/symptomes`, {
    method: 'POST',
    headers: { ...authHeader(), 'Content-type': 'application/json' },
    body: JSON.stringify({ titre }),
  }).then(res => {
    if (!res.ok) {
      throw {
        status: res.status,
        message: 'Erreur lors de la création du symptôme',
      };
    }
    return res.json();
  });
