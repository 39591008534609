import React from 'react';
import Client from '../../models/client';

interface IAddressFormProps {
  address: Client['adresse'];
  onUpdate: (updates: Client['adresse']) => void;
}

const AddressForm: React.FC<IAddressFormProps> = ({
  address,
  onUpdate,
}: IAddressFormProps) => {
  const handleOnUpdate = (e: any): void => {
    onUpdate({ ...address, [e.target.name]: e.target.value });
  };

  return (
    <div className="address-form">
      <div className="address-form_content">
        <div className="input-row">
          <label>Numéro et libellé de voie.</label>
          <input
            name="line1"
            value={address.line1 || ''}
            onChange={handleOnUpdate}
          />
        </div>
        <div className="input-row">
          <label>Suite adresse / lieu dit et autre mention.</label>
          <input
            name="line2"
            value={address.line2 || ''}
            onChange={handleOnUpdate}
          />
        </div>
        <div className="input-row">
          <label>Entrée, bâtiment, immeuble, résidence.</label>
          <input
            name="line3"
            value={address.line3 || ''}
            onChange={handleOnUpdate}
          />
        </div>
        <div className="input-row">
          <label>Étage, couloir, escalier, appartement.</label>
          <input
            name="line4"
            value={address.line4 || ''}
            onChange={handleOnUpdate}
          />
        </div>
      </div>
      <div className="input-row">
        <label>Code postal</label>
        <input name="cp" value={address.cp || ''} onChange={handleOnUpdate} />
      </div>
      <div className="input-row">
        <label>Ville</label>
        <input
          name="ville"
          value={address.ville || ''}
          onChange={handleOnUpdate}
        />
      </div>
      <div className="input-row">
        <label>Pays</label>
        <input
          name="pays"
          value={address.pays || ''}
          onChange={handleOnUpdate}
        />
      </div>
    </div>
  );
};

export default AddressForm;
