import React, { useCallback, useEffect, useRef } from 'react';
import ClientSearchBar from '../../components/ClientSearchBar';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearFilters,
  setDisplayed,
  updateFilters,
} from '../../store/clients/clients.actions';
import { getClient } from '../../store/client/client.actions';
import { StoreState } from '../../store';
import './ClientListPanel.scss';
import ClientList from '../../components/ClientList';
import Loader from '../../components/_generic/Loader';

const ClientListPanel: React.FC = () => {
  const { filters, clients, displayed, loading } = useSelector(
    (state: StoreState) => state.clientsState
  );

  const dispatch = useDispatch();

  const onClientSelect = useCallback((id: string): void => {
    dispatch(getClient(id));
    dispatch(setDisplayed(false));
    dispatch(clearFilters());
  }, []);

  const htmlref = useRef(null);

  const handleClick = (e: any) => {
    if (htmlref && htmlref.current) {
      if (!(htmlref.current as any).contains(e.target)) {
        dispatch(setDisplayed(false));
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [htmlref]);

  const noResult =
    !loading &&
    (filters.id?.length ||
      0 > 0 ||
      filters.nom?.length ||
      0 > 0 ||
      filters.prenom?.length ||
      0 > 0) &&
    clients.length === 0;

  return (
    <div className="clients-panel">
      <div className="clients-panel_content" ref={htmlref}>
        <ClientSearchBar
          filters={filters}
          onUpdate={(updates: any) => dispatch(updateFilters(updates))}
        />
        {displayed && !loading ? (
          <ClientList
            clients={clients}
            onSelect={onClientSelect}
            errors={null}
          />
        ) : null}
        {noResult && <div className="py-2 px-4 dgrey">Aucun résultat</div>}
        {loading && (
          <div className="my-2">
            <Loader size={30} show={loading} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ClientListPanel;
