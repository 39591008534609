import React, { FC, MouseEvent, useEffect, useRef, useState } from 'react';
import {
  getSymptomes,
  createSymptome,
  getSymptomeByTitle,
} from '../../services/symptomeService/symptome.service';
import Symptome from '../../models/symptome';
import { query } from '../../core/AuthProvider';
import Suggestion from '../../types/suggestion';
import './SymptomeSuggester.scss';

interface ISymptomeSuggester {
  onAddSymptome: (symptome: Symptome) => void;
}

const SymptomeSuggester: FC<ISymptomeSuggester> = ({
  onAddSymptome,
}: ISymptomeSuggester) => {
  const [searchField, setSearchField] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [error, setError] = useState<string>('Cette plainte existe déjà');
  const inputRef = useRef<HTMLInputElement>(null);

  const handleCreateSymptome = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (searchField && searchField.length > 0) {
      // Check if the symptome exists
      query(getSymptomeByTitle(searchField))
        .then((symptomes: Symptome[]) => {
          if (symptomes.length > 0) {
            setError('Cette plainte existe déjà');
            throw new Error('A symptome with this title already exists');
          }
          return query(createSymptome(searchField));
        })
        .then(handleOnAddSymptome)
        .catch(err => console.error(err));
    }
  };

  const handleOnChange = (searchField: string): void => {
    setError('');
    if (searchField && searchField.length > 0)
      query(getSymptomes(searchField))
        .then(setSuggestions)
        .catch(err => console.error(err));
    else setSuggestions([]);
  };

  const handleOnAddSymptome = (symptome: Symptome): void => {
    onAddSymptome(symptome);
    setSearchField('');
    if (inputRef.current) inputRef.current.focus();
  };

  useEffect(() => {
    handleOnChange(searchField);
  }, [searchField]);

  return (
    <div className="symptome-suggester">
      <form>
        <input
          ref={inputRef}
          placeholder={'trouver/ajouter'}
          className="symptome-suggester_input"
          value={searchField}
          onChange={e => setSearchField(e.target.value)}
        />
        {searchField.length > 0 ? (
          <button type="submit" onClick={handleCreateSymptome}>
            <span className="material-icons">check</span>
          </button>
        ) : null}
      </form>
      <div className="symptome-suggester_items">
        {/* Error message */}
        {error && <span className="pl-1 py-2 text-sm red">{error}</span>}
        {/* Suggestions */}
        {suggestions.map((s: Suggestion, index: number) => (
          <button
            className="symptome-suggester_item"
            key={index}
            onClick={() => handleOnAddSymptome(s)}
          >
            {s.titre}
          </button>
        ))}
      </div>
    </div>
  );
};

export default SymptomeSuggester;
