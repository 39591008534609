import { toast } from 'react-hot-toast';

const toastErrorConfig = {
  style: {
    minWidth: '250px',
    color: 'white',
    backgroundColor: '#da5552',
  },
  duration: 30000,
};

export const toastError = (message: string) =>
  toast(
    (t: any) => (
      <div className="full-width  d-flex align-items-center justify-content-between">
        <span className="material-icons">error</span>
        <b className="mx-3">{message}</b>
        <span
          onClick={() => toast.dismiss(t.id)}
          className="material-icons clickable"
        >
          clear
        </span>
      </div>
    ),
    toastErrorConfig
  );
