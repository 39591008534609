import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../store';
import { login } from '../../store/app/app.actions';
import './LoginPage.scss';
import Loader from '../../components/_generic/Loader';

const LoginPage: React.FC = () => {
  const dispatch = useDispatch();
  const { error } = useSelector((state: StoreState) => state.appState);
  const [userName, setUserName] = useState<string>('');
  const [pwd, setPwd] = useState<string>('');
  const [showPwd, setShowPwd] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const onLog = (e: React.ChangeEvent<HTMLFormElement>) => {
    setLoading(true);
    e.preventDefault();
    if (userName && pwd) dispatch(login(userName, pwd));
  };

  useEffect(() => {
    setLoading(false);
  }, [error]);

  return (
    <div className="login-page column">
      <div className="login-page_form">
        <h2 className="login-page_form_label">Connexion</h2>
        <form className="login-page_form_content" onSubmit={onLog}>
          <div className="login-input-row">
            <label>Identifiant</label>
            <div className="row">
              <input
                autoFocus={true}
                name={'userName'}
                value={userName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setUserName(e.target.value)
                }
              />
            </div>
          </div>
          <div className="password-input-row">
            <label>Mot de passe</label>
            <div className="row">
              <input
                name={'pwd'}
                type={showPwd ? 'text' : 'password'}
                value={pwd}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setPwd(e.target.value)
                }
              />
              <button type="button" onClick={() => setShowPwd(!showPwd)}>
                <span className="material-icons">visibility</span>
              </button>
            </div>
          </div>

          <button
            className="login-button"
            type="submit"
            disabled={!userName.length || !pwd.length}
          >
            Valider
          </button>
          {loading && (
            <div className="pt-2">
              <Loader show={loading} size={30} />
            </div>
          )}
        </form>
      </div>
      <div className="login-page_form_error mt-2">{error}</div>
    </div>
  );
};

export default LoginPage;
