import Client from '../../models/client';
import { Consultation } from '../../models/consultation';
import { ThunkDispatch } from 'redux-thunk';
import { UnselectConsultation } from '../consultation/type';

export interface ClientState {
  client?: Client;
  updates?: Partial<Client>;
  edited: boolean;
  loading: boolean;
  fetching: boolean;
  error?: string;
}

export const SET_CLIENT_AS_NEW = 'SET_CLIENT_AS_NEW',
  GET_CLIENT_STARTED = 'GET_CLIENT_STARTED',
  GET_CLIENT_SUCCESS = 'GET_CLIENT_SUCCESS',
  GET_CLIENT_ERROR = 'GET_CLIENT_ERROR',
  UPDATE_CLIENT = 'UPDATE_CLIENT',
  UNSELECT_CLIENT = 'UNSELECT_CLIENT',
  FETCH_CLIENT_STARTED = 'FETCH_CLIENT_STARTED',
  FETCH_CLIENT_SUCCESS = 'FETCH_CLIENT_SUCCESS',
  FETCH_CLIENT_ERROR = 'FETCH_CLIENT_ERROR',
  DELETE_CLIENT_STARTED = 'DELETE_CLIENT_STARTED',
  DELETE_CLIENT_SUCCESS = 'DELETE_CLIENT_SUCCESS',
  DELETE_CLIENT_ERROR = 'DELETE_CLIENT_ERROR',
  ADD_CONSULTATION = 'ADD_CONSULTATION',
  REMOVE_CONSULTATION = 'REMOVE_CONSULTATION';

export interface SetClientAsNew {
  type: typeof SET_CLIENT_AS_NEW;
}

export interface GetClientStarted {
  type: typeof GET_CLIENT_STARTED;
}

export interface GetClientSuccess {
  type: typeof GET_CLIENT_SUCCESS;
  payload: Client;
}

export interface GetClientError {
  type: typeof GET_CLIENT_ERROR;
  payload: string;
}

export interface UnselectClient {
  type: typeof UNSELECT_CLIENT;
}

export interface UpdateClient {
  type: typeof UPDATE_CLIENT;
  payload: Partial<Client>;
}

export interface FetchClientStarted {
  type: typeof FETCH_CLIENT_STARTED;
}

export interface FetchClientSuccess {
  type: typeof FETCH_CLIENT_SUCCESS;
  payload: Client;
}

export interface FetchClientError {
  type: typeof FETCH_CLIENT_ERROR;
  payload: string;
}

export interface DeleteClientStarted {
  type: typeof DELETE_CLIENT_STARTED;
}

export interface DeleteClientSuccess {
  type: typeof DELETE_CLIENT_SUCCESS;
}

export interface DeleteClientError {
  type: typeof DELETE_CLIENT_ERROR;
  payload: string;
}

export interface AddConsultation {
  type: typeof ADD_CONSULTATION;
  payload: {
    id: Consultation['id'];
    date: Consultation['date'];
  };
}

export interface RemoveConsultation {
  type: typeof REMOVE_CONSULTATION;
  payload: string;
}

export type ClientDispatch = ThunkDispatch<
  ClientState,
  void,
  ClientAction | UnselectConsultation
>;

export type ClientAction =
  | SetClientAsNew
  | GetClientStarted
  | GetClientSuccess
  | GetClientError
  | UnselectClient
  | UpdateClient
  | FetchClientStarted
  | FetchClientSuccess
  | FetchClientError
  | DeleteClientStarted
  | DeleteClientSuccess
  | DeleteClientError
  | AddConsultation
  | RemoveConsultation;
