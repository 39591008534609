import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getConsultation,
  setConsultationAsNew,
} from '../../store/consultation/consultation.actions';
import { StoreState } from '../../store';
import './ClientConsultationList.scss';

const ClientConsultationList: React.FC<any> = ({ consultations }: any) => {
  const dispatch = useDispatch();
  const { consultation } = useSelector(
    (state: StoreState) => state.consultationState
  );

  const { client } = useSelector((state: StoreState) => state.clientState);

  const consultationsElements = consultations
    .map((c: any) => ({ ...c, id: Number(c.id) }))
    .sort((c1: any, c2: any) => {
      if (new Date(c1.date) === new Date(c2.date))
        return c1.id < c2.id ? -1 : 1;
      return new Date(c1.date) < new Date(c2.date) ? 1 : -1;
    })
    .map((c: { id: string; date: string }) => (
      <button
        type="button"
        key={c.id}
        className={
          'consultation-list_item ' +
          (c.id == consultation?.id ? 'selected' : '')
        }
        onClick={() => dispatch(getConsultation(c.id))}
      >
        {c.date}
      </button>
    ));

  return (
    <div className="client-consultation-list">
      <div className="client-consultation-list-header full-width d-flex justify-content-between align-items-center mb-3">
        <label>Consultations</label>

        <button
          className="add-consultation-btn mr-2"
          onClick={() => dispatch(setConsultationAsNew(client?.id as string))}
        >
          <span className="material-icons">add</span>
        </button>
      </div>

      {consultationsElements?.length > 0 ? (
        consultationsElements
      ) : (
        <span className="py-2 dgrey">Aucune consultation trouvée.</span>
      )}
    </div>
  );
};

export default ClientConsultationList;
