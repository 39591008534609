import { Reducer } from 'redux';
import {
  ConsultationState,
  ADD_FICHIERS,
  DELETE_CONSULTATION_STARTED,
  DELETE_CONSULTATION_ERROR,
  DELETE_CONSULTATION_SUCCESS,
  DELETE_PREPARATION,
  FETCH_CONSULTATION_SUCCESS,
  GET_CONSULTATION_ERROR,
  GET_CONSULTATION_STARTED,
  GET_CONSULTATION_SUCCESS,
  SET_PREPARATION_SELECTED,
  UNSELECT_CONSULTATION,
  UPDATE_CONSULTATION,
  ConsultationAction,
} from './type';

const consultationReducer: Reducer = (
  state: ConsultationState,
  action: ConsultationAction
) => {
  if (state) state.error = undefined;
  switch (action.type) {
    case GET_CONSULTATION_STARTED:
      return { ...state, loading: true };
    case GET_CONSULTATION_SUCCESS:
      return {
        ...state,
        consultation: action.payload,
        preparationSelected: 0,
        loading: false,
      };
    case GET_CONSULTATION_ERROR:
      return { ...state, error: action.payload };
    case UNSELECT_CONSULTATION:
      return {
        consultation: undefined,
        loading: false,
        preparationSelected: 0,
      };
    case UPDATE_CONSULTATION:
      return {
        ...state,
        consultation: { ...state.consultation, ...action.payload },
      };
    case SET_PREPARATION_SELECTED:
      return { ...state, preparationSelected: action.payload };
    case DELETE_PREPARATION:
      if (!state.consultation || !state.consultation.preparations.length)
        return { ...state };
      const prepss = [...state.consultation.preparations];
      prepss.splice(action.payload, 1);
      return {
        ...state,
        consultation: { ...state.consultation, preparations: prepss },
      };
    case ADD_FICHIERS:
      if (state.consultation)
        return {
          ...state,
          consultation: {
            ...state.consultation,
            fichiers: [...state.consultation?.fichiers, ...action.payload],
          },
        };
      else return { ...state };
    case FETCH_CONSULTATION_SUCCESS:
      return { ...state, consultation: action.payload };
    case DELETE_CONSULTATION_STARTED:
      return { ...state, loading: true };
    case DELETE_CONSULTATION_SUCCESS:
      return {
        ...state,
        loading: false,
        consultation: undefined,
        preparationSelected: 0,
      };
    case DELETE_CONSULTATION_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return { ...state };
  }
};

export default consultationReducer;
