import React, { ReactElement, useEffect, useState } from 'react';
import styles from './DateRangeSelect.module.scss';

type DateRangeSelectProps = {
  dateRange?: DateRange;
  onChange: (change: DateRange) => void;
  reset?: () => void;
};

const DateRangeSelect: React.FC<DateRangeSelectProps> = ({
  dateRange,
  onChange,
}): ReactElement => {
  const [from, setFrom] = useState<string>('');
  const [to, setTo] = useState<string>('');

  useEffect(() => {
    if (!dateRange) return;
    setFrom(dateRange.from);
    setTo(dateRange.to);
  }, [dateRange]);

  useEffect(() => {
    onChange({ from, to });
  }, [from, to]);

  const handleFromChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFrom(e.target.value);
  };

  const handleToChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTo(e.target.value);
  };

  return (
    <form className={styles.DateRangeSelect}>
      <input
        type="text"
        placeholder="Du"
        value={from}
        onFocus={e => (e.currentTarget.type = 'date')}
        onChange={handleFromChange}
      />
      <input
        type="text"
        placeholder="Au"
        value={to}
        onFocus={e => (e.currentTarget.type = 'date')}
        onChange={handleToChange}
      />
    </form>
  );
};

export default DateRangeSelect;
