import { Reducer } from 'redux';
import clientService from '../../services/clientService/client.service';

import {
  FETCH_CLIENT_ERROR,
  FETCH_CLIENT_STARTED,
  FETCH_CLIENT_SUCCESS,
  GET_CLIENT_ERROR,
  GET_CLIENT_STARTED,
  GET_CLIENT_SUCCESS,
  SET_CLIENT_AS_NEW,
  UNSELECT_CLIENT,
  UPDATE_CLIENT,
  ADD_CONSULTATION,
  REMOVE_CONSULTATION,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_STARTED,
  DELETE_CLIENT_ERROR,
  ClientState,
} from './type';

const clientReducer: Reducer = (state: ClientState, action: any) => {
  if (state) state.error = undefined;
  switch (action.type) {
    case SET_CLIENT_AS_NEW:
      return {
        ...state,
        updates: {},
        client: clientService.getNewClient(),
        edited: false,
      };
    case GET_CLIENT_STARTED:
      return { ...state, loading: true };
    case GET_CLIENT_SUCCESS:
      return {
        ...state,
        client: action.payload,
        updates: {},
        loading: false,
        edited: false,
      };
    case GET_CLIENT_ERROR:
      return { ...state, error: action.payload, loading: false, edited: false };
    case UPDATE_CLIENT:
      return {
        ...state,
        updates: { ...state.updates, ...action.payload },
        edited: true,
      };
    case UNSELECT_CLIENT:
      return {
        client: undefined,
        fetching: false,
        loading: false,
        error: undefined,
      };
    case FETCH_CLIENT_STARTED:
      return { ...state, fetching: true, loading: true };
    case FETCH_CLIENT_SUCCESS:
      return {
        ...state,
        updates: {},
        client: action.payload,
        fetching: false,
        edited: false,
        loading: false,
      };
    case FETCH_CLIENT_ERROR:
      return { ...state, error: action.error, fetching: false, loading: false };
    case ADD_CONSULTATION:
      if (!state.client) return;
      return {
        ...state,
        client: {
          ...state.client,
          consultations: [...state.client.consultations, action.payload],
        },
      };
    case REMOVE_CONSULTATION:
      if (!state.client) return;
      const index = state.client.consultations.findIndex(
        (c: any) => c.id === action.payload
      );

      const consultations = [...state.client.consultations];
      consultations.splice(index, 1);
      return {
        ...state,
        client: {
          ...state.client,
          consultations,
        },
      };

    /* Delete client */
    case DELETE_CLIENT_STARTED:
      return {
        ...state,
        loading: true,
      };
    case DELETE_CLIENT_SUCCESS:
      return {
        client: null,
        updates: null,
        edited: false,
        loading: false,
        fetching: false,
      };
    case DELETE_CLIENT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return { ...state };
  }
};

export default clientReducer;
